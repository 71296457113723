import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Authenticator as AmplifyAuthenticator,
  // ConfirmSignIn as AmplifyConfirmSignIn,
  // ConfirmSignUp as AmplifyConfirmSignUp,
  // ForgotPassword as AmplifyForgotPassword,
  // Greetings as AmplifyGreetings,
  // Loading as AmplifyLoading,
  // RequireNewPassword as AmplifyRequireNewPassword,
  // SignIn as AmplifySignIn,
  // SignOut as AmplifySignOut,
  // SignUp as AmplifySignUp,
  // TOTPSetup as AmplifyTOTPSetup,
  // VerifyContact as AmplifyVerifyContact
} from 'aws-amplify-react';

import {
  ConfirmSignIn,
  ConfirmSignUp,
  ForgotPassword,
  RequireNewPassword,
  SignIn,
  SignInEmailOnly,
  SignInEmailOnlyChallengeCode,
  SignedIn,
  // SignUp,
  StateLogger,
  TOTPSetup,
  VerifyContact,
} from '.';
import { withRegistry } from '../../hoc';

import Footer from '../Footer';

// const _authStates = Object.assign({
////   'confirmSignIn': 'confirmSignIn',
////   'confirmSignUp': 'confirmSignUp',
////   'forgotPassword': 'forgotPassword',
//--   'loading': 'loading',
////   'requireNewPassword': 'requireNewPassword',

////   'signIn': 'signIn',
////   'signedOut': 'signedOut',

//   'signedUp': 'signedUp',
//   'signUp': 'signUp',
//   'TOTPSetup': 'TOTPSetup',
////   'verifyContact': 'verifyContact'
// });

function handleStateChange(state, data) {
  // console.log({state, data});
}


class Authenticator extends React.Component {

  render() {
    // console.log("Authenticator:render", {props: this.props});
    const { registry } = this.props;

    const authenticationType = registry.appUserPoolAuthenticationType

    return (
      <Fragment>
      <AmplifyAuthenticator 
        hideDefault
        handleStateChange={ handleStateChange }
      >
        <StateLogger authenticationType={authenticationType}/>
        <ConfirmSignIn authenticationType={authenticationType} />
        <ConfirmSignUp authenticationType={authenticationType} />
        <ForgotPassword authenticationType={authenticationType} />
        <RequireNewPassword authenticationType={authenticationType} />
        <SignIn authenticationType={authenticationType} />
        <SignInEmailOnly authenticationType={authenticationType} />
        <SignInEmailOnlyChallengeCode authenticationType={authenticationType} />
        <SignedIn authenticationType={authenticationType} />  
        {/* <SignUp authenticationType={authenticationType} /> */}
        <TOTPSetup authenticationType={authenticationType} />
        <VerifyContact authenticationType={authenticationType} />      
      </AmplifyAuthenticator>
      <Footer />
      </Fragment>
    );
  }
}

Authenticator.propTypes = {
  registry: PropTypes.object.isRequired,
};


export default withRegistry(Authenticator);