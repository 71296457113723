import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { withRegistry } from '../hoc';

const styles = theme => ({
  root: {
    textAlign: 'center',
    margin: theme.spacing(2)
  }
});

class Footer extends React.Component {

  render() {
    const { classes, registry } = this.props;

    return (
      <Typography className={ classes.root } variant='body1' dangerouslySetInnerHTML={{ __html: registry.appCopyright }} />
    );
  }
}

export default withRegistry(withStyles(styles, { withTheme: true })(Footer));
