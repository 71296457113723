import React, { Fragment } from 'react';

import { ListItem } from '.';

const List = (props) => {
  const { data } = props;
  // console.log("List:render", {props})

  let filteredData = (data || []).filter(e => e.published);

  if (filteredData.length === 0) {
    return (
      <div>No Active Conferences</div>
    )
  }
  
  return (
    <Fragment>
      { filteredData.map(e => <ListItem key={e.id} data={e} />) }
    </Fragment>
  );
}

export default List;
