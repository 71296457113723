import React from 'react';
import classnames from 'classnames';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { Auth, Logger, JS } from 'aws-amplify';
import { AuthPiece } from 'aws-amplify-react'
import AuthPieceWrapper from './AuthPieceWrapper';

import { withRegistry } from '../../hoc';
import styles from './_styles';

// eslint-disable-next-line
const logger = new Logger('Authenticator');

class ConfirmSignIn extends AuthPiece {
    constructor(props) {
        super(props);

        this._validAuthStates = ['confirmSignIn'];
        this.confirm = this.confirm.bind(this);
        this.checkContact = this.checkContact.bind(this);
        this.state = {
            code: undefined,
            mfaType: 'SMS'
        };
    }

    checkContact(user) {
        if (!Auth || typeof Auth.verifiedContact !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }

        Auth.verifiedContact(user)
            .then(data => {
                if (!JS.isEmpty(data.verified)) {
                    this.changeState('signedIn', user);
                } else {
                    const newUser = Object.assign(user, data);
                    this.changeState('verifyContact', newUser);
                }
            });
    }

    confirm() {
        const user = this.props.authData;
        const { code } = this.state;
        const mfaType = user.challengeName === 'SOFTWARE_TOKEN_MFA' ? 'SOFTWARE_TOKEN_MFA' : null;
        if (!Auth || typeof Auth.confirmSignIn !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }

        Auth.confirmSignIn(user, code, mfaType)
            .then(() => {
                this.checkContact(user);
            })
            .catch(err => this.error(err));
    }

    componentDidUpdate() {
        // logger.debug('component did update with props', this.props);
        const user = this.props.authData;
        const mfaType = user && user.challengeName === 'SOFTWARE_TOKEN_MFA'?
            'TOTP' : 'SMS';
        if (this.state.mfaType !== mfaType) this.setState({ mfaType });
    }

    showComponent(theme) {
        const { classes, hide } = this.props;
        if (hide && hide.includes(ConfirmSignIn)) { return null; }

        return (
          <AuthPieceWrapper>
            <Typography className={ classes.row } variant='subtitle1'>{'Confirm ' + this.state.mfaType + ' Code'}</Typography>
            <TextField 
              className={ classes.row }               
              name="code" 
              placeholder='Code'
              required
              inputProps={ { autoComplete: "one-time-code" } }
              onChange={ (e) => this.setState({ code: e.target.value })} />
            <div className={ classnames(classes.row, classes.buttons) }>
              <Button className={ classes.button } variant='contained' color='primary' onClick={ this.signIn }>Confirm</Button>
              <Button className={ classes.button } variant='text' color='default' onClick={() => this.changeState('signIn') }>Back to log in</Button>
            </div>
          </AuthPieceWrapper>
        );
    }
}

export default withRegistry(styles(ConfirmSignIn));