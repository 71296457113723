import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import querystring from 'query-string';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { Auth, Logger, JS } from 'aws-amplify';
import { AuthPiece } from 'aws-amplify-react'
import AuthPieceWrapper from './AuthPieceWrapper';

import { AUTHENTICATION_TYPE, AUTHENTICATION_TYPES } from './Enums';

import styles from './_styles';

const logger = new Logger('Authenticator');

class SignIn extends AuthPiece {

  constructor(props) {
    super(props);

    this.checkContact = this.checkContact.bind(this);
    this.signIn = this.signIn.bind(this);

    this._validAuthStates = ['signIn', 'signedOut', 'signedUp'];
    this.state = { username: '', password: '', rememberMe: false, checkedForCurrentUser: false};
  }

  checkContact(user) {
    Auth.verifiedContact(user)
      .then(data => {
        if (!JS.isEmpty(data.verified)) {
          this.changeState('signedIn', user);
        } else {
          user = Object.assign(user, data);
          this.changeState('verifyContact', user);
        }
      });
  }

  signIn() {
    const { username, password } = this.state;
    // const { username, password, rememberMe } = this.state;
    
    Auth.signIn(username.trim(), password)
      .then(user => {
        logger.debug(user);
        if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
          logger.debug('confirm user with ' + user.challengeName);
          this.changeState('confirmSignIn', user);
        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          logger.debug('require new password', user.challengeParam);
          this.changeState('requireNewPassword', user);
        } else if (user.challengeName === 'MFA_SETUP') {
          logger.debug('TOTP setup', user.challengeParam);
          this.changeState('TOTPSetup', user);
        }
        else {
          this.checkContact(user);
        }
      })
      .catch(err => {
          this.error(err)
      });
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  showComponent() {
    const { classes, authenticationType, location, onRememberMe, rememberMeValue  } = this.props;

    if (authenticationType !== AUTHENTICATION_TYPE.VERIFIED_EMAIL_AND_PASSWORD) {
      return null;
    }
    const values = querystring.parse(location.search.slice(1));
    // console.log({values});
    return (
      <AuthPieceWrapper>
        <Typography className={ classes.row } variant='subtitle1'>Please enter the email you registered with.</Typography>
        <TextField 
          className={ classes.row } 
          name="username" 
          placeholder='Type your email'
          onChange={ (e) => this.setState({ username: e.target.value })} />
        <TextField 
          className={ classes.row } 
          name="password" type="password" 
          placeholder='Type your password'
          onChange={ (e) => this.setState({ password: e.target.value })} />
        <FormControlLabel
          className={ classnames( classes.row, classes.button) }
          control={
            <Checkbox
              checked={ rememberMeValue }
              className={ classes.paddingLeftZero }
              onChange={ onRememberMe }
              value="rememberMe"
              color="primary"
            />
          }
          label="Remember Me"
        />        
        <div className={ classnames(classes.row, classes.buttons) }>
          <Button className={ classes.button } variant='contained' color='primary' onClick={ this.signIn }>Log in</Button>
          <Button className={ classes.button } variant='text' color='default' onClick={() => this.props.history.push(`${decodeURIComponent(values.redirectUrl)}`) }>Cancel</Button>
        </div>
      </AuthPieceWrapper>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  authenticationType: PropTypes.oneOf(AUTHENTICATION_TYPES).isRequired
};

export default withRouter(styles(SignIn));