import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

import SpeakerItem from './SpeakerItem'
import SearchField from '../../Components/SearchField';
import { TablePagination, Typography } from '@material-ui/core';
import { getCachedClientId, getCachedEventId } from '../../hoc/withData';
import { API, graphqlOperation } from 'aws-amplify';
import muGraphQL from '../../mu-graphql';

const styles = theme => ({
  root: {
    width: '100%',
    minHeight: 300,
    paddingTop: theme.spacing(3),
  },
  searchInput: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.up('lg')]: {
      flexWrap: 'nowrap',
    }
  },
  searchField: {
    flex: '0 0 100%',
    [theme.breakpoints.up('lg')]: {
      flex: '0 0 50%',
    }
  },
  pageRoot: {
    flex: '0 0 100%',
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      flex: '0 0 50%',
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    }
  },
  pageToolbar: {
    padding: 0,
    display: 'inline-flex',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    }
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  divider: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
});
class Speakers extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      rowsPerPage: 25,
      searchValue: '',
      speakers: null
    };

    this.handleChangePage = this.handleChangePage.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    this.__mounted = true;

    API.graphql(graphqlOperation(
      muGraphQL.events.LIST_SPEAKERS,
      { clientID: getCachedClientId(), eventID: getCachedEventId(match.params.slug) }
    ))
      .then((result) => {
        this.setState({ speakers: result.data.listSpeakers });
      })
      .catch(e => {
        console.log(`An error occured. (${JSON.stringify(e)})`);
        this.setState({ error: `An error occured. (${JSON.stringify(e)})` })
      });

    API.graphql(graphqlOperation(
      muGraphQL.events.LIST_SESSIONS,
      { clientID: getCachedClientId(), eventID: getCachedEventId(match.params.slug) }
    ))
      .then((result) => {
        this.setState({ sessions: result.data.listSessions });
      })
      .catch(e => {
        console.log(`An error occured. (${JSON.stringify(e)})`);
        this.setState({ error: `An error occured. (${JSON.stringify(e)})` })
      });
  }

  componentWillUnmount() {
    this.__mounted = false;
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  render() {
    const { classes } = this.props;
    const { page, rowsPerPage, searchValue } = this.state;

    const speakers = this.state.speakers ? (
      this.state.speakers.filter((speaker) => {
        if(searchValue === '') return true;

        const title = speaker.title;
        const firstName = speaker.person && speaker.person.firstName;
        const middleName = speaker.person && speaker.person.middleName;
        const lastName = speaker.person && speaker.person.lastName;
        const affiliation = speaker.affiliation;

        const searchParts = [];

        if(title) searchParts.push(title.trim());
        if(firstName) searchParts.push(firstName.trim());
        if(middleName) searchParts.push(middleName.trim());
        if(lastName) searchParts.push(lastName.trim());
        if(affiliation) searchParts.push(affiliation.trim());

        const fullText = searchParts.join(' ');

        if(fullText && fullText.toLocaleLowerCase().includes(searchValue.toLowerCase())){
          return true;
        }
        return false;
      })
    )
      : [];

    if (speakers && this.state.sessions) {
      const sessionTimes = [];
      this.state.sessions.forEach(sesh => sesh.times.forEach(time => sessionTimes.push(time)));

      speakers.forEach(speaker => {
        const speakTimes = sessionTimes.filter(sessionTime => sessionTime.speakers.some(seshSpeaker => seshSpeaker.id === speaker.id));
        speaker.times = speakTimes;
      });
    }

    return (
      <div className={ classes.root }>
        <div>
          <div className={ classes.searchInput }>
            <SearchField
              className={ classes.searchField }
              onChange={ (value) => this.setState({ searchValue: value })}
              onClose={ () => this.setState({ searchValue: '' })}
              placeholder={ 'Search speakers' }
              value={ searchValue }
            />

            <TablePagination
              classes={{
                root: classes.pageRoot,
                toolbar: classes.pageToolbar
              }}
              component='div'
              count={ speakers.length }
              onPageChange={ this.handleChangePage }
              page={ page }
              rowsPerPage={ rowsPerPage }
              rowsPerPageOptions={[]}
            />

          </div>
          {
            speakers.length !== 0 ?
              speakers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((speaker, i) => {
                return (
                  <div key={ speaker.id }>
                    <SpeakerItem data={speaker} />
                    { i !== rowsPerPage - 1 &&
                      <Divider className={ classes.divider } />
                    }
                  </div>
                )
              })
            : <Typography variant='h6' color='textSecondary'>No speakers</Typography>
          }
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Speakers);
