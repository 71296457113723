import * as React from 'react';
import classnames from 'classnames';

import { Auth, Logger } from 'aws-amplify';
import { AuthPiece } from 'aws-amplify-react'
import AuthPieceWrapper from './AuthPieceWrapper';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import styles from './_styles';

const logger = new Logger('Authenticator');

class ConfirmSignUp extends AuthPiece {
    constructor(props) {
        super(props);

        this._validAuthStates = ['confirmSignUp'];
        this.confirm = this.confirm.bind(this);
        this.resend = this.resend.bind(this);
    }

    confirm() {
        const username = this.usernameFromAuthData();
        const { code } = this.state;
        if (!Auth || typeof Auth.confirmSignUp !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }

        Auth.confirmSignUp(username, code)
            .then(() => this.changeState('signedUp'))
            .catch(err => this.error(err));
    }

    resend() {
        const username = this.usernameFromAuthData();
        if (!Auth || typeof Auth.resendSignUp !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        Auth.resendSignUp(username)
            .then(() => logger.debug('code resent'))
            .catch(err => this.error(err));
    }

    showComponent() {
        const { classes, hide } = this.props;
        const username = this.usernameFromAuthData();

        if (hide && hide.includes(ConfirmSignUp)) { return null; }

        return (
          <AuthPieceWrapper>
            <Typography className={ classes.row } variant='h5'>Confirm sign up</Typography>
            <TextField 
              className={ classes.row } 
              name="username" 
              value={username}
              disabled={true}
              placeholder='Type your email'
              onChange={ (e) => this.setState({ username: e.target.value })} />
            <TextField 
              className={ classes.row } 
              name="code"
              inputProps={ { autoComplete: 'one-time-code' }}
              placeholder='Type your access code'
              onChange={ (e) => this.setState({ code: e.target.value })} />
            <div className={ classnames(classes.row, classes.buttons) }>
              <Button className={ classes.button } variant='contained' color='primary' onClick={ this.signIn }>Confirm</Button>
              <Button className={ classes.button } variant='text' color='default' onClick={this.resend}>Lost your code? Resend Code</Button>          
              <Button className={ classes.button } variant='text' color='default' onClick={() => this.changeState('signIn')}>Back to Log In</Button>
            </div>
          </AuthPieceWrapper>            
        );
    }
}

export default styles(ConfirmSignUp);
