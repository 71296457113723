const eventFields = `
    id
    clientID
    videoIntegration {
        id
        apiKey
        apiSecret
        type
    }
    name
    ETag
    slug
    description
    summary
    image {
        url
    }
    registrationUrl
    published
    dates {
        start
        end
    }
    helpForm
    timeZone
`;

const LIST_EVENTS = `
query ListEvents($clientId: ID){
  listEvents(clientID: $clientId) {
    __typename
    id
    clientID
    name
    ETag
    slug
    description
    summary
    image {
      url
    }
    dates {
      name
      start
      end
      city
      state
    }
    defaultSessionLength
    published
    registrationUrl
  }
}
`;

const GET_VIDEO_MEETING_JOIN_DATA = `
query GetVideoMeetingJoinData(
  $clientId: ID!,
  $eventId: ID!,
  $videoMeeting: IVideoMeeting!
){
  getVideoMeetingJoinData(
    clientID: $clientId,
    eventID: $eventId,
    videoMeeting: $videoMeeting
  ) {
    value
    key
    secret
  }
}
`;

const GET_EVENT_ID_NAME_BY_SLUG = `
query GetEventBySlug($clientId: ID, $slug: String!) {
  getEventBySlug(clientID: $clientId, eventSlug: $slug) {
    __typename
    id
    clientID
    name
  }
}
`;

const GET_EVENT = `
    query GetEvent($clientID: ID, $eventID: ID) {
        getEvent(clientID: $clientID, eventID: $eventID) {
            ${eventFields}
        }
    }
`;

const LIST_RESOURCES = `
    query ListResources($clientID: ID, $eventID: ID!) {
        listResources(clientID: $clientID, eventID: $eventID) {
            id
            content
            shortPhrase
            title
        }
    }
`;

const LIST_SPONSORS = `
    query ListSponsors($clientID: ID, $eventID: ID!) {
        listSponsors(clientID: $clientID, eventID: $eventID) {
            id
            name
            shortPhrase
            description
            priority
            level {
                id
                name
                order
                rgbColor
            }
            logo {
                url
            }
        }
    }
`;

const LIST_SPONSOR_LEVELS = `
    query ListSponsorLevels($clientID: ID, $eventID: ID!) {
        listSponsorLevels(clientID: $clientID, eventID: $eventID) {
            id
            name
            order
            rgbColor
        }
    }
`;

const LIST_EXHIBITORS = `
    query ListExhibitors($clientID: ID, $eventID: ID!) {
        listExhibitors(clientID: $clientID, eventID: $eventID) {
            id
            name
            shortPhrase
            description
            category
            booth
            logo {
                url
            }
        }
    }
`;

const LIST_SPEAKERS = `
    query ListSpeakers($clientID: ID, $eventID: ID!) {
        listSpeakers(clientID: $clientID, eventID: $eventID) {
            id
            affiliation
            title
            bio
            person {
                prefix
                firstName
                middleName
                lastName
                phoneNumbers {
                    number
                }
                emails {
                    address
                }
            }
            headshot {
                url
            }
        }
    }
`;

const LIST_SESSIONS = `
    query ListSessions($clientID: ID, $eventID: ID!) {
        listSessions(clientID: $clientID, eventID: $eventID) {
            id
            name
            description
            duration
            type {
                id
                name
                rgbColor
            }
            level {
                id
                name
            }
            materials {
                url
                attributes {
                    name
                    size
                    modified
                    type
                }
            }
            times {
                id
                day
                time
                venue {
                    id
                    name
                }
                hall {
                    id
                    name
                }
                session {
                  name
                }
                speakers {
                  id
                }
            }
        }
    }
`;

export const GET_SESSION = `
    query GetSession($clientID: ID, $sessionID: ID!) {
        getSession(clientID: $clientID, sessionID: $sessionID) {
            id
            name
            description
            duration
            type {
                id
                name
                rgbColor
            }
            level {
                id
                name
            }
            materials {
                url
                attributes {
                    name
                    size
                    modified
                    type
                }
            }
            times {
                id
                day
                time
                venue {
                    id
                    name
                }
                hall {
                    id
                    name
                }
                speakers {
                    id
                    affiliation
                    title
                    person {
                        firstName
                        lastName
                    }
                    headshot {
                        url
                    }
                }
                session {
                    id
                    duration
                }
            }
        }
    }
`;

const muGraphQL = {
  events: {
    LIST_EVENTS,
    LIST_EXHIBITORS,
    LIST_RESOURCES,
    LIST_SESSIONS,
    LIST_SPEAKERS,
    LIST_SPONSOR_LEVELS,
    LIST_SPONSORS,
    GET_EVENT,
    GET_EVENT_ID_NAME_BY_SLUG,
    GET_SESSION,
    GET_VIDEO_MEETING_JOIN_DATA
  },
};

export default muGraphQL;
