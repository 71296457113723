import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import classnames from 'classnames';

import { ConsoleLogger as Logger, Hub } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { AuthPiece } from 'aws-amplify-react'
// import SignOut from './SignOut';
// import { withGoogle, withAmazon, withFacebook, withAuth0 } from '';

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'


const logger = new Logger('Greetings');

const styles = theme => ({
  signInLink: {
    textDecoration: 'none',
    whiteSpace: 'nowrap',
  }
})

class Greetings extends AuthPiece {
    constructor(props) {
        super(props);
        this.state = {};
        Hub.listen('auth', (capsule) => this.onHubCapsule(capsule));
    }

    componentDidMount() {
        this._isMounted = true;
        this.findState();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    findState(){
        if (!this.props.authState && !this.props.authData) {
            Auth.currentAuthenticatedUser()
            .then(user => {
                this.setState({
                    authState: 'signedIn',
                    authData: user,
                    stateFromStorage: true
                })
            })
            .catch(err => logger.debug(err));
        }
    }

    onHubCapsule(capsule) {
        if (this._isMounted) {
            const { channel, payload } = capsule;
            if (channel === 'auth' && payload.event === 'signIn') {
                this.setState({
                    authState: 'signedIn',
                    authData: payload.data
                })
                if  (!this.props.authState) {
                    this.setState({stateFromStorage: true})
                }
            } else if (channel === 'auth' && payload.event === 'signOut' && (!this.props.authState)) {
                this.setState({
                    authState: 'signIn'
                });
            }
        }
    }

    inGreeting() { return `Log in`; }
    outGreeting() { return 'Log out'; }

    render() {
        const { classes, className:classNameProp, hide, location } = this.props;
        if (hide && hide.includes(Greetings)) { return null; }

        const authState  = this.props.authState || this.state.authState;
        const signedIn = (authState === 'signedIn');

        const greeting = signedIn ? this.outGreeting() : this.inGreeting();

        // console.log({greeting, signedIn, location});

        if (!signedIn) {
          return (
            <Link className={ classnames(classes.signInLink, classNameProp) } to={`/signin?redirectUrl=${encodeURIComponent(location.pathname+location.search)}`}>
              <Typography color='primary' variant='body1'>{greeting}</Typography>
            </Link>
          );
        } else {
          return (
            <Typography
              className={ classnames(classNameProp) }
              color='primary' variant='body1'
              onClick={ () => this.props.history.push(`/signOut${location.search}`) }
            >{greeting}</Typography>
          );
        }
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(Greetings));
