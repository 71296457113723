import React from 'react';

import Amplify from 'aws-amplify';

export const REGISTRY_RESULT_KEY = 'registryResultKey';

export default function withRegistry(WrappedComponent) {
  return class extends React.Component {

    render() {
      let registryResult = Amplify.Cache.getItem(REGISTRY_RESULT_KEY);

      return (<WrappedComponent registry={registryResult} {...this.props} />);
    }
  }
}
