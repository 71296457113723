import React from 'react';

import Amplify from 'aws-amplify';
import { BrowserStorageCache, InMemoryCache } from '@aws-amplify/cache';

const authConfigure = (value) => {
  if (!value) {
    Amplify.Auth.configure({
      storage: InMemoryCache
    });
  } else {
    Amplify.Auth.configure({
      storage: null
    });      
  }
}

export default function withRememberMe(WrappedComponent) {

  let rememberMe = false;

  return class extends React.PureComponent {
    constructor(props) {
      super(props);
      this.rememberMe = this.rememberMe.bind(this);
      this.setRememberMe = this.setRememberMe.bind(this);
      this.rememberMeSignOut = this.rememberMeSignOut.bind(this);

      let cacheValue = BrowserStorageCache.getItem('__rememberMe');
      if (cacheValue === null) {
        cacheValue = false;
      }
      this.setRememberMe(cacheValue);
    }    

    async componentDidMount() {
      window.addEventListener("beforeunload", this.verifyRememberMe.bind(this));
    }
  
    componentWillUnmount() {
      window.removeEventListener("beforeunload", this.verifyRememberMe.bind(this));
    }

    async verifyRememberMe() {
      try {
        await Amplify.Auth.currentSession();
      }
      catch (error) {
        this.rememberMeSignOut();  
      }
    }
      
    setRememberMe(value) {
      BrowserStorageCache.setItem('__rememberMe', value);
      rememberMe = value;
      authConfigure(rememberMe);
    }

    rememberMe(value) {
      this.setRememberMe(value.target.checked);
      this.forceUpdate();
    }

    rememberMeSignOut() {
      this.setRememberMe(false);
    }

    render() {  
      return (<WrappedComponent onRememberMe={ this.rememberMe } rememberMeSignOut={ this.rememberMeSignOut } rememberMeValue={ rememberMe } {...this.props} />);
    }
  }
}
