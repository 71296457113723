import React from 'react';

import { withStyles } from '@material-ui/core/styles';
// import './AppUpdater.css'

const styles = () => ({
  updateAvailable: {
    position: 'absolute',
    top: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 'calc(50% - 175px)',
    width: 350,
    height: 36,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    backgroundColor: '#333',
    color: '#fff',
    textAlign: 'center',
    fontSize: '1em',
    lineHeight: '36px',
    zIndex: 200000,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#444',
    }
  },
});

class AppUpdater extends React.Component {

  constructor(props) {
    super(props);

    this.updateApplication = this.updateApplication.bind(this);

    this.state = { showUpdateAvailable: false }
  }

  async componentDidMount() {
    const self = this;
    // console.log("AppUpdater:componentDidMount");
    let refreshing;
    navigator.serviceWorker.addEventListener('controllerchange',
      function() {
        // console.log('navigator.serviceWorker:controllerchange', {refreshing})
        if (refreshing) return;
        refreshing = true;
        window.location.reload();
      }
    );

    navigator.serviceWorker.getRegistration()
      .then(function (reg) {        
        // console.log("AppUpdater.getRegistration", {reg, waiting: !!reg.waiting})

        function awaitStateChange() {
          // console.log('listenForWaitingServiceWorker:awaitStateChange');
          reg.installing.addEventListener('statechange', function() {
            if (this.state === 'installed') self.setState({ showUpdateAvailable: true });
          });
        }
        if (!reg) return;
        // console.log({reg, waiting: reg.waiting, installing: reg.installing});
        if (reg.waiting) return self.setState({ showUpdateAvailable: true });
        if (reg.installing) awaitStateChange();
        reg.addEventListener('updatefound', awaitStateChange);
      })
      .catch(function (err) {
        console.log("Error getting registered service worker", {err});
      });
    
  }

  async updateApplication() {
    const reg = await navigator.serviceWorker.getRegistration()
    // console.log("AuthenticatorApp:updateApplication", {reg, waiting: reg.waiting})
      if (reg && reg.waiting) {
        // console.log('AuthenticatorApp:updateApplication:skipWaiting', {reg});
        reg.waiting.postMessage('skipWaiting');
      }
  }

  render() {

    const { classes } = this.props;
    const { showUpdateAvailable } = this.state;

    // console.log("AppUpdater:render", {props: this.props, state: this.state, showUpdateAvailable});
    if (!showUpdateAvailable) return null;

    return (<div className={classes.updateAvailable} onClick={this.updateApplication}>A new version is available  REFRESH</div>);
  }
}

export default withStyles(styles)(AppUpdater);