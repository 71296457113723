import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

import { withRegistry } from '../hoc';
import { Typography } from '@material-ui/core';
import { Greetings } from './Login';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing(2)}px 0`,
  },
  logoLink: {
    display: 'block',
    textDecoration: 'none',
    maxWidth: 800,
    maxHeight: 120,
    overflow: 'hidden',
  },
  logo: {
    height: 'auto',
    width: '100%',
  },
  signInContainer: {
    display: 'flex',
    flexDirection: 'column-reverse',
    marginLeft: theme.spacing(2),
  },
  signIn: {
    // textTransform: 'uppercase',
    letterSpacing: '0.02857em',
    fontWeight: 500,
    fontSize: '0.875rem',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  signInLink: {
    textDecoration: 'none',
    whiteSpace: 'nowrap',
  }
});
class Header extends React.PureComponent {
  render() {
    // console.log('Header:render', {props: this.props})
    const { classes, location, registry } = this.props;
    const logo = registry.appHeaderLogoUrl && registry.appHeaderLogoUrl.url ? registry.appHeaderLogoUrl.url : null;
    const name = registry.appName || '';

    return (
      <Fragment>
        <div className={ classes.root }>
          <Link className={ classes.logoLink } to={`/${location.search}`}>
            { logo ?
                <img className={ classes.logo } src={logo} alt={name} />
              : <Typography variant='h4'>{name}</Typography>
            }
          </Link>
          <div className={ classes.signInContainer }>
            <Greetings />
          </div>
        </div>
        <Divider />
      </Fragment>
    );
  }
}

export default withRegistry(withRouter(withStyles(styles, { withTheme: true })(Header)));
