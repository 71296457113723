import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';

import Amplify, { API, graphqlOperation } from 'aws-amplify';

import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import { REGISTRY_RESULT_KEY } from './withRegistry';
import muGraphQL from '../mu-graphql';

export const EVENT_ID_KEY = 'eventIdKey';
export const EVENT_NAME_KEY = 'eventNameKey';
export const EVENT_START_DATE = 'eventStartDate';
export const EVENT_VIDEO_INTEGRATION = 'eventVideoIntegration';

// Disabling the cache for now. It's causing issues after logging-in with queries that should have new data now that the user is authorized.
const enableCache = false;

/**
 * It might be better just to cache the entire event...but for now just the 4 pieces cached.
 * This should really use an Apollo Client with an InMemoryCache like we do with admin-console,
 * then queries wouldn't always have to call back to the server for data. But since this project
 * doesn't use the apollo client with a cache to make graphql calls (and has it's caching
 * mechanism disabled per line 18), we shouldn't ask for the event on every page to get event details.
 * Maybe when we switch to using hooks (so we can upgrade to the lastest react-router-dom), we can
 * also add the apollo client and an in memory cache.
 */
export function setCachedEventData(data) {
  setCachedEventId(data.id);
  setCachedEventName(data.name);
  setCachedEventStartDate(data.dates[0].start);
  setCachedVideoIntegration(data.videoIntegration);
}

export function getCachedClientId() {
  const registryResult = Amplify.Cache.getItem(REGISTRY_RESULT_KEY);
  return registryResult.clientID;
}

function setCachedEventId(eventId) {
  Amplify.Cache.setItem(EVENT_ID_KEY, eventId);
}

export function getCachedEventId(eventSlug) {
  const eventId = Amplify.Cache.getItem(EVENT_ID_KEY);
  if (eventId) return eventId;

  getEventIdAndName(eventSlug);
}

function setCachedEventName(eventName) {
  Amplify.Cache.setItem(EVENT_NAME_KEY, eventName);
}

export function getCachedEventName() {
  const eventName = Amplify.Cache.getItem(EVENT_NAME_KEY);
  return eventName;
}

function setCachedEventStartDate(start) {
  Amplify.Cache.setItem(EVENT_START_DATE, start);
}

export function getCachedEventStartDate() {
  const start = Amplify.Cache.getItem(EVENT_START_DATE);
  return start;
}

function setCachedVideoIntegration(videoIntegration) {
  Amplify.Cache.setItem(EVENT_VIDEO_INTEGRATION, videoIntegration);
}

export function getCachedVideoIntegration() {
  const videoIntegration = Amplify.Cache.getItem(EVENT_VIDEO_INTEGRATION);
  return videoIntegration;
}

function getEventIdAndName(eventSlug) {
  API.graphql(graphqlOperation(
    muGraphQL.events.GET_EVENT_ID_NAME_BY_SLUG,
    { clientID: getCachedClientId(), eventSlug: eventSlug }
  ))
    .then((result) => {
      setCachedEventId(result.getEventBySlug.id);
      setCachedEventData(result.getEventBySlug);
    })
    .catch(e => {
      console.log(`An error occured. (${JSON.stringify(e)})`);
      this.setState({ error: `An error occured. (${JSON.stringify(e)})` })
    });
}

export default function withData(options, WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      if (options && options.query) {
        this.state = { loading: true, accessDenied: false };
      }
    }

    async componentDidMount() {
      const { match } = this.props;
      let { accessDenied } = this.state;

      this.__mounted = true;

      let params = {};
      if (options && options.variables) {
        for (let p in options.variables) {
          if (Array.isArray(options.variables[p])) {
            //if queryParameters is an array lookup value from this
            let objRef = this;
            for (let part of options.variables[p]) {
              objRef = objRef[part] || ' ';
            }
            params[p] = objRef;
          } else {
            params[p] =
              match.params[options.variables[p]] || options.variables[p];
          }
        }
      }

      if (params['clientId'] === undefined) {
        params['clientId'] = getCachedClientId();
      }

      let cacheKey = undefined;

      try {
        const indexOfName = options.query.indexOf(' ');
        const indexOfClientQuery = options.query.indexOf('(');

        const queryName = options.query
          .substring(indexOfName, indexOfClientQuery)
          .trim();
        let paramsCacheKey = [];
        for (let key in params) {
          if (typeof params[key] !== 'object') {
            paramsCacheKey.push(`${key}_${params[key]}`);
          } else {
            _.each(params[key], (value, key) => {
              paramsCacheKey.push(`${key}_${value}`);
            });
          }
        }
        cacheKey = `${queryName}__${paramsCacheKey.join('__')}`;

        const cacheData = Amplify.Cache.getItem(cacheKey);

        if (enableCache && cacheData) {
          //allow the cache data to be returned.
          if (this.__mounted) {
            this.setState({
              data: cacheData.data[options.queryDataAccessor],
              loading: false,
              refreshingCache: true,
            });
          }

          //verify if cache is stale
          // await API.graphql
          // continue checking in the background
          const { data: cacheCheckData } = await API.graphql(
            graphqlOperation(options.cacheQuery, cacheData.params)
          );

          if (
            cacheCheckData[options.queryDataAccessor].hasOwnProperty('ETag') &&
            cacheData.data[options.queryDataAccessor].hasOwnProperty('ETag')
          ) {
            if (
              cacheCheckData[options.queryDataAccessor].ETag ===
              cacheData.data[options.queryDataAccessor].ETag
            ) {
              if (this.__mounted) {
                this.setState({ refreshingCache: false });
              }
              return;
            }
          } else {
            if (
              _.isEqual(
                cacheCheckData[options.queryDataAccessor],
                cacheData.data[options.queryDataAccessor]
              )
            ) {
              if (this.__mounted) {
                this.setState({ refreshingCache: false });
              }
              return;
            }
          }
        }

        var graphqlQuery = graphqlOperation(options.query, params);
        const { data } = await API.graphql(graphqlQuery);
        if (options.cacheQuery && options.queryDataAccessor) {
          enableCache && Amplify.Cache.setItem(cacheKey, { data, params });
        }
        if (this.__mounted) {
          this.setState({
            data: data[options.queryDataAccessor],
            loading: false,
            refreshingCache: false,
          });
        }
      } catch ({ data, errors }) {
        //check for access denied
        console.log('withData:ERROR', { data, errors });
        if (data && data[options.queryDataAccessor]) {
          const result = data[options.queryDataAccessor];
          if (
            cacheKey &&
            result &&
            result.__typename &&
            result.id &&
            result.ETag
          ) {
            enableCache && Amplify.Cache.setItem(cacheKey, { data, params });
          }
        }
        if (this.__mounted && data) {
          this.setState({
            data: data[options.queryDataAccessor],
            error: errors,
            loading: false,
            refreshingCache: false,
            accessDenied,
          });
        } else if (this.__mounted) {
          this.setState({
            error: errors,
            loading: false,
            refreshingCache: false,
            accessDenied,
          });
        }
      }
    }

    componentWillUnmount() {
      this.__mounted = false;
    }

    render() {
      const { location } = this.props;
      const {
        accessDenied,
        data,
        error,
        loading,
        refreshingCache,
      } = this.state;

      let useLoading = true;

      if (options && options.hasOwnProperty('showLoading')) {
        useLoading = options.showLoading;
      }

      if (useLoading && loading) {
        return (
          <CircularProgress
            style={{
              margin: '24px auto',
              display: 'block',
            }}
          />
        );
      }

      if (accessDenied) {
        return (
          <Redirect to={`/login?redirectUrl=${encodeURI(location.pathname)}`} />
        );
      }

      if (refreshingCache) {
      }
      return (
        <Fragment>
          {refreshingCache && (
            <LinearProgress
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 10000,
                height: 2,
              }}
              color="primary"
            />
          )}
          <WrappedComponent
            data={data}
            error={error}
            loading={loading}
            {...this.props}
          />
        </Fragment>
      );
    }
  };
}
