import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import urljoin from 'url-join';

// import logo from './logo.svg';
// import './App.css';
import 'typeface-roboto'

/* Material UI */
import { MuiThemeProvider, createTheme, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress';

import Amplify from 'aws-amplify';

import AppUpdater from './AppUpdater';
import { Authenticator, Home, SignOut } from './Views';
import { REGISTRY_RESULT_KEY } from './hoc/withRegistry';

import appConfig from './app.config';
import appConfigDev from './app.config.dev';

import { withChangeFavicon, withRememberMe } from './hoc';

Amplify.API.configure({
  endpoints: [{
    name: appConfig.registryApiName,
    endpoint: appConfig.registryApiUrl,
    service: appConfig.registryApiService,
  }]
});

const styles = theme => ({
  progressRoot: {
    width: '100%'
  },
  centerProgress: {
    display: 'block',
    margin: 'auto',
    marginTop: theme.spacing(3)
  }
})
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { registry: undefined, unexpectedError: false }

    this.getBaseTheme = this.getBaseTheme.bind(this);
  }

  async componentDidMount() {
    let n = appConfig.appQueryStringParameters[0];
    let q = {};
    let host = window.location.host;
    if (host === "localhost:3000" || host === "localhost:3001") {
      host = appConfigDev.url
    }
    q[n] = host;

    let requestConfig = {
      headers: {
      },
      response: false,
      queryStringParameters: q
    }

    let registryResult = undefined;
    try {
      try {
        registryResult = await Amplify.API.get(appConfig.registryApiName, appConfig.appPath, requestConfig);
      }
      catch {
        console.log("/v1/app?domainName endpoint not active yet, revert to /vi/app/{id} for moment.");
        const path = urljoin(appConfig.appPath, host.split('.')[0]);
        registryResult = await Amplify.API.get(appConfig.registryApiName, path)
      }
    }
    catch (e) {
      registryResult = Amplify.Cache.getItem(REGISTRY_RESULT_KEY);
    }
    Amplify.Cache.setItem(REGISTRY_RESULT_KEY, registryResult);

    if (!registryResult) {
      this.setState({ unexpectedError: true });
      return;
    }

    document.title = registryResult.appName;
    this.props.changeFavicon(registryResult.appIconUrl)

    Amplify.Auth.configure({
      region: `${registryResult.cloudRegion}`,
      userPoolId: `${registryResult.appUserPoolID}`,
      userPoolWebClientId: `${registryResult.appUserPoolClientID}`,
    });

    Amplify.API.configure({
      aws_appsync_graphqlEndpoint: registryResult.graphqlEndpoint.url,
      aws_appsync_region: registryResult.cloudRegion,
      aws_appsync_authenticationType: 'API_KEY',
      aws_appsync_apiKey: registryResult.graphqlEndpoint.apiKey,
      graphql_headers: async () => {
          try {
            const token = (await Amplify.Auth.currentSession()).getAccessToken().getJwtToken()
            return { 'authorization': token}
          } catch (e) {
            // Appears to mostly happen when user is not signed-in
            console.warn('Failed to get user auth token:', e)
          }
      },
      endpoints: [{
        name: appConfig.registryApiName,
        endpoint: appConfig.registryApiUrl,
        service: appConfig.registryApiService,
      }]
    });

    this.setState({ registry: registryResult })
  }

  getBaseTheme() {
    return createTheme({
      palette: {
        primary: {
          main: '#e3e3e3',
        },
        secondary: {
          main: '#333333',
        },
      },
      typography: {
        useNextVariants: true,
      },
    });
  }

  getHexColor(colorStr) {
    let a = document.createElement('div');
    a.style.color = colorStr;
    let colors = window.getComputedStyle( document.body.appendChild(a) ).color.match(/\d+/g).map(function(a){ return parseInt(a,10); });
    document.body.removeChild(a);
    return (colors.length >= 3) ? '#' + (((1 << 24) + (colors[0] << 16) + (colors[1] << 8) + colors[2]).toString(16).substr(1)) : false;
  }

  getTheme() {
    const { registry } = this.state;

    let primaryColor = '#5372e6';
    let secondaryColor = '#ff7900';
    let themeType = 'light';

    if (registry && registry.appPrimaryRgbColor) {
      primaryColor = this.getHexColor(registry.appPrimaryRgbColor);
    }

    if (registry && registry.appSecondaryRgbColor) {
      secondaryColor = this.getHexColor(registry.appSecondaryRgbColor);
    }

    if (registry && registry.appThemeType) {
      themeType = registry.appThemeType === 'DARK' ? 'dark' : 'light'
    }

    return createTheme({
      palette: {
        type: themeType,
        primary: {
          main: primaryColor,
        },
        secondary: {
          main: secondaryColor,
        },
      },
      breakpoints: {
        keys: ["xs", "sm", "md", "lg", "xl", "xxl", "xxxl", "xxxxl"],
        values: {
          xs: 0,
          sm: 376,
          md: 426,
          lg: 600,
          xl: 1025,
          xxl: 1441,
          xxxl: 1921,
          xxxxl: 2561,
        }
      },
      typography: {
        useNextVariants: true,
        h6: {
          opacity: 0.85
        },
        subtitle1: {
          opacity: 0.85
        },
        subtitle2: {
          opacity: 0.85
        },
        body1: {
          opacity: 0.85
        },
        body2: {
          opacity: 0.85
        }
      }
    });
  }

  render() {
    const { classes } = this.props;
    const { registry, unexpectedError } = this.state;

    if (!registry) {
      return (
        <MuiThemeProvider theme={this.getBaseTheme()}>
          <CssBaseline />
          <div className={ classes.progressRoot }>
            { !unexpectedError ?
            <CircularProgress className={ classes.centerProgress } />
            : <div className={ classes.centerProgress }>UnexpectedError</div>
            }
          </div>
        </MuiThemeProvider>
      )
    }

    return (
      <MuiThemeProvider theme={this.getTheme()}>
        <CssBaseline />
        <BrowserRouter>
          <Switch>
            <Route component={Authenticator} path="/signin" />
            <Route component={SignOut} path="/signout" />
            <Route component={Home} />
          </Switch>
        </BrowserRouter>
        <AppUpdater />
      </MuiThemeProvider>
    );
  }
}

export default withChangeFavicon(withRememberMe(withStyles(styles, { withTheme: true })(App)));
