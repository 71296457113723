import React from 'react';

import { InMemoryCache } from '@aws-amplify/cache';

const __memoryCache = InMemoryCache;

export default function withMemoryCache(WrappedComponent) {

  return class extends React.Component {

    render() {  
      return (<WrappedComponent memoryCache={__memoryCache} {...this.props} />);
    }
  }
}
