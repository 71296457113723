import React from 'react';
import { getCachedClientId } from "../../hoc/withData";
import { withRouter } from 'react-router-dom';
import muGraphQL from '../../mu-graphql';
import $ from 'jquery';
import { ZoomMtg } from '@zoomus/websdk';
import Auth from '@aws-amplify/auth';
import { API, graphqlOperation } from "aws-amplify";
import { getVideoMeetingFromUrl } from "../../Utils/VideoMeetingUtils";

$('#zmmtg-root').css('visibility', 'hidden');

class VideoSessionView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
    };
  }

  updateDimensions() {
    try {
      const { size } = this.state;

      const currentSize = window.innerWidth < 768 ? 'small' : 'normal';
      if (size !== currentSize && this.__mounted) {
        this.setState({ size: currentSize });
      }
    } catch {}
  }

  componentDidMount() {
    this.__mounted = true;

    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));

    const variables = getVideoMeetingFromUrl(
      this.props.location.pathname,
      this.props.location.search
    );
    API.graphql(graphqlOperation(
      muGraphQL.events.GET_VIDEO_MEETING_JOIN_DATA,
      { ...variables, clientId: getCachedClientId() }
    ))
      .then((result) => {
        const joinData = result.data.getVideoMeetingJoinData;
        const signature = joinData.find((d) => d.key === "signature");
        const apiKey = joinData.find((d) => d.key === "apiKey");
        this.joinVideoCall({
          apiKey: apiKey && apiKey.value,
          signature: signature && signature.value,
          meetingId: variables.videoMeeting.meetingId,
          passcode: variables.videoMeeting.passcode,
        });
      })
      .catch(e => this.setState({ error: `An error occured. (${JSON.stringify(e)})` }));
  }

  componentWillUnmount() {
    this.__mounted = false;
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  joinVideoCall({ apiKey, signature, meetingId, passcode }) {
    Auth.currentAuthenticatedUser()
      .then(user => {
        return this.joinZoom({
          signature,
          apiKey,
          meetingId,
          passcode,
          userEmail: user.username,
        });
      })
      .catch(e => {
        console.warn(e);
        if (e === "not authenticated")
          this.setState({ error: 'Unauthorized. Please try to log in to view this meeting.' });
        else this.setState({ error: 'An error occurred.' });
        $('#zmmtg-root').css('visibility', 'hidden');
      });
  }

  joinZoom(meetingConfig) {
    $('#zmmtg-root').css('visibility', 'visible');
    this.beginJoin(meetingConfig);
  }

  beginJoin(mc) {
    console.log(JSON.stringify(ZoomMtg.checkSystemRequirements()));
    ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.8/lib');
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();
    ZoomMtg.init({
      leaveUrl: this.props.location.pathname + '/leave',
      isSupportAV: true,
      disableInvite: true,
      disableJoinAudio: true,
      success: function () {
        ZoomMtg.join({
          signature: mc.signature,
          apiKey: mc.apiKey,
          meetingNumber: mc.meetingId,
          passWord: mc.passcode,
          userName: mc.userEmail,
          userEmail: mc.userEmail,
          success: () => {},
          error: function (e) {
            $('#zmmtg-root').css('visibility', 'hidden');
            console.error('ZoomMtg join failed', e);
          },
        });
      },
      error: function (e) {
        $('#zmmtg-root').css('visibility', 'hidden');
        console.error('ZoomMtg init failed', e);
      },
    });
  }

  render() {
    return (
      <>
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
          integrity="sha384-JcKb8q3iqJ61gNV9KGb8thSsNjpSL0n8PARn9HuZOnIxN0hoP+VmmDGMN5t9UJ0Z"
          crossOrigin="anonymous"
        ></link>
        <p role="alertdialog">{this.state.error}</p>
      </>
    );
  }
}

export default withRouter(VideoSessionView);
