import React from 'react';
import classnames from 'classnames';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { Auth, I18n, Logger } from 'aws-amplify';
import { AuthPiece } from 'aws-amplify-react'
import AuthPieceWrapper from './AuthPieceWrapper';

import { withRegistry } from '../../hoc';

import styles from './_styles';

const logger = new Logger('Authenticator');

class VerifyContact extends AuthPiece {
  constructor(props) {
    super(props);
    
    this._validAuthStates = ['verifyContact'];
    this.verify = this.verify.bind(this);
    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = { verifyAttr: null };
  }

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
  };

  verify() {
    const { contact, checkedValue } = this.inputs;
    if (!contact) {
      this.error('Neither Email nor Phone Number selected');
      return;
    }

    if (!Auth || typeof Auth.verifyCurrentUserAttribute !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }

    Auth.verifyCurrentUserAttribute(checkedValue)
        .then(data => {
          logger.debug(data);
          this.setState({ verifyAttr: checkedValue });
        })
        .catch(err => this.error(err));
  }

  submit() {
    const attr = this.state.verifyAttr;
    const { code } = this.inputs;
    if (!Auth || typeof Auth.verifyCurrentUserAttributeSubmit !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }
    Auth.verifyCurrentUserAttributeSubmit(attr, code)
        .then(data => {
          logger.debug(data);
          this.changeState('signedIn', this.props.authData);
          this.setState({ verifyAttr: null });
        })
        .catch(err => this.error(err));
  }

  verifyView() {
    const { authData } = this.props;
    const user = authData;
    if (!user) {
      logger.debug('no user for verify');
      return null;
    }
    const { unverified } = user;
    if (!unverified) {
      logger.debug('no unverified on user');
      return null;
    }
    const { email, phone_number } = unverified;
    return (
      <div>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label=""
            name="contact"
            // className={classes.group}
            value={this.state.value}
            onChange={ this.handleInputChange }
          >
            { email ? <FormControlLabel value="email" control={<Radio />} label={I18n.get('Email')} /> : null }
            { phone_number ? <FormControlLabel value="phone_number" control={<Radio />} label={I18n.get('Phone Number')} /> : null }
          </RadioGroup>
        </FormControl>
      </div>
    );
  }

  submitView() {
    const { classes } = this.props;

    return (
      <div>
        <TextField
          autoFocus
          label={I18n.get('Code')}
          placeholder={I18n.get('Code')}
          key="code"
          name="code"
          autoComplete="off"
          className={classes.row}
          onChange={this.handleInputChange}
          margin="normal"
        />
      </div>
    );
  }

  showComponent(theme) {
    const { authData, classes } = this.props;
    // const { username, password, rememberMe } = this.state;    

    return (      
      <AuthPieceWrapper>
        <Typography className={ classes.row } variant='subtitle1'>Account recovery requires verified contact information.</Typography>
        { this.state.verifyAttr ? this.submitView() : this.verifyView() }
        <div className={ classnames(classes.row, classes.buttons) }>
          { this.state.verifyAttr ?
            <Button className={ classes.button } variant='contained' color='primary' onClick={ this.submit }>Submit</Button> :
            <Button className={ classes.button } variant='contained' color='primary' onClick={ this.verify }>Verify</Button>
          }
          <Button className={ classes.button } color='default' onClick={() => this.changeState('signedIn', authData)}>Skip</Button>
        </div>
      </AuthPieceWrapper>           
    )
  }
}

export default withRegistry(styles(VerifyContact));
