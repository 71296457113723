// import React from 'react';
import { AuthPiece } from 'aws-amplify-react'

const _authStates = Object.assign({
  'confirmSignIn': 'confirmSignIn',
  'confirmSignUp': 'confirmSignUp',
  'forgotPassword': 'forgotPassword',
  'loading': 'loading',
  'requireNewPassword': 'requireNewPassword',

  'signIn': 'signIn',
  'signedOut': 'signedOut',

  'signedUp': 'signedUp',
  'signUp': 'signUp',
  'TOTPSetup': 'TOTPSetup',
  'verifyContact': 'verifyContact'
});

class StateLogger extends AuthPiece {
  constructor(props) {
    super(props);
    
    this._validAuthStates = [
      _authStates.confirmSignIn, 
      _authStates.confirmSignUp, 
      _authStates.forgotPassword, 
      _authStates.loading, 
      _authStates.requireNewPassword, 
      _authStates.signIn, 
      _authStates.signedOut,
      _authStates.signUp, 
      _authStates.TOTPSetup, 
      _authStates.verifyContact
    ];
  }

  showComponent(theme) {
    const { authState, authData, authenticationType } = this.props;
    console.log({authState, authData, authenticationType})

    return (null);
  }
}

export default StateLogger;
