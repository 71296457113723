import React from 'react';

import { TextField, InputAdornment } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import SearchIcon from '@material-ui/icons/Search';

const styles = theme => ({
  searchIcon: {
    opacity: .85,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(),
  },
});

class SearchField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isSearching: false,
    }
  }

  handleBlur = (event) => {
    const { onBlur } = this.props;

    if(onBlur){
      onBlur(event);
    }
    !event.target.value && this.setState({ isSearching: false });
  }

  handleSearch = (event) => {
    const { onChange } = this.props;
    const value = event.target.value;
    let self = this;

    if (this.search) {
      clearTimeout(this.search);
    }

    this.search = setTimeout(() => {
      self.search = undefined;
      onChange(value);
    }, 250);
  }

  render() {
    const { classes, className:classNameProp, placeholder, value } = this.props;

    return(
      <TextField
        className={ classNameProp }
        defaultValue={ value }
        label={ 'Search' }
        // disableUnderline
        onChange={ this.handleSearch }
        onBlur={ this.handleBlur }
        InputProps={ {
          startAdornment:
            (<InputAdornment position='start'>
              <SearchIcon className={classes.searchIcon} />
            </InputAdornment>)
        }}
        variant='outlined'
        placeholder={ placeholder }
      />
    )
  }
}

export default withStyles(styles, { withTheme: true })(SearchField);
