import React from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import { ScheduleDayList } from '.'
import { withMemoryCache } from '../../hoc';
import { getCachedClientId, getCachedEventId } from '../../hoc/withData';
import { API, graphqlOperation } from 'aws-amplify';
import muGraphQL from '../../mu-graphql';

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(),
    minHeight: 300
  },
  tabSubTitle: {
    fontSize: '.9em',
    lineHeight: `${theme.spacing()}px`,
  },
  typography: {
    padding: theme.spacing(3),
  },
  viewsRoot: {
    marginTop: theme.spacing(3)
  }
});

function TabLabel({ dayOfWeek, monthDay, tabSubTitle }) {
  return (
    <div>
      <div>{dayOfWeek}</div>
      <Typography className={ tabSubTitle } color='textSecondary'>{monthDay}</Typography>
    </div>
  );
}

class Schedule extends React.PureComponent {
  constructor(props) {
    super(props);

    const { match, memoryCache } = props;

    const lastScheduleDayCacheKey = `${getCachedEventId(match.params.slug)}:scheduleDayKey`;
    // TODO KLH this cached value doesn't work when changing tabs
    memoryCache.setItem(lastScheduleDayCacheKey, match.params.day ? Number.parseInt(match.params.day) : 0)
    const lastScheduleDay = memoryCache.getItem(lastScheduleDayCacheKey);

    this.state = {
      eTag: '',
      value: lastScheduleDay,
      dayTabs: null,
      eventData: null,
      customTabs: null,
      customViews: null,
      lastScheduleDayCacheKey,
      sessions: null
    };
  }

  buildTabState(size)
  {
    const { classes } = this.props;
    const { eventData, sessions } = this.state;
    const dayTabs = [];
    const customTabs = [];
    const customViews = [];

    if (eventData && eventData.dates && eventData.dates.length === 1) {
      const startDate = moment(eventData.dates[0].start, 'YYYY-MM-DD');
      const endDate = moment(eventData.dates[0].end, 'YYYY-MM-DD');

      const days = endDate.diff(startDate, 'days');

      for(let i = 0; i <= days; i++) {
        let tabDay = startDate.clone();
        tabDay.add(i, 'days');
        dayTabs.push({ i, date: tabDay, monthDay: tabDay.format('MMM D').toUpperCase(), dayOfWeek: tabDay.format('dddd').toUpperCase(), sessionTimes: [] });
      }

      for(let i = 0; i < (sessions || []).length; i++) {
        const session = sessions[i];
        for(let j = 0; j < (session.times || []).length; j++) {
          const sessionTime = session.times[j];

          if (!sessionTime || !sessionTime.day || !sessionTime.time) {
            continue;
          }

          let sessionTimeDayTab = dayTabs[sessionTime.day - 1];
          if (sessionTimeDayTab === undefined) {
            continue;
          }

          let time = moment(`1970-01-01T${sessionTime.time}`, "YYYY-MM-DDTHH:mm");
          let endTime = time.clone().add(session.duration, 'minutes');

          sessionTimeDayTab.sessionTimes.push({
            id: sessionTime.id,
            name: session.name,
            description: session.description,
            duration: session.duration,
            materials: session.materials,
            startTime: time.format('hh:mm A'),
            endTime: endTime.format('hh:mm A'),
            __sortTime: time.unix(),
            __sortTime2: endTime.unix(),
            venue: sessionTime.venue,
            hall: sessionTime.hall,
            speakers: sessionTime.speakers,
          });
        }
      }

      for(let i = 0; i < dayTabs.length; i++) {
        dayTabs[i].sessionTimes.sort(Schedule.sessionTimeSort);

        customTabs.push(
          <Tab key={i}
            disableRipple
            label={
              <TabLabel
                dayOfWeek={ dayTabs[i].dayOfWeek }
                monthDay={ dayTabs[i].monthDay }
                tabSubTitle={ classes.tabSubTitle }
              />
            }
          />);

        customViews.push(<ScheduleDayList key={i} data={dayTabs[i].sessionTimes} size={ size } />);

      }
    }

    const eTag = eventData ? eventData.eTag : '';

    return { customTabs, customViews, dayTabs, eTag }
  }

  static sessionTimeSort(a,b) {

    if (a.__sortTime < b.__sortTime) {
      return -1;
    }

    if (a.__sortTime > b.__sortTime) {
      return 1;
    }

    if (a.__sortTime2 < b.__sortTime2) {
      return -1;
    }
    if (a.__sortTime2 > b.__sortTime2) {
      return 1;
    }

    return (a.name.localeCompare(b.name))
  }

  componentDidUpdate() {
    const { match } = this.props;
    const { value, eTag, eventData, size } = this.state;
    const newValue = match.params.day ? Number(match.params.day) : 0;

    let newState = undefined;

    if (eventData && eventData.ETag !== eTag) {
      newState = this.buildTabState(size);
    }

    if (value !== newValue) {
      if (newState) {
        newState['value'] = newValue;
      }
      else {
        newState = { value: newValue };
      }
    }

    if (newState) {
      this.setState({ ...newState, eTag: eventData.ETag});
    }
  }

  updateDimensions() {
    const { size } = this.state;

    const currentSize = window.innerWidth < 768 ? "small" : "normal";
    if (size !== currentSize && this.__mounted) {
      const { customViews } = this.buildTabState(currentSize);
      this.setState({ size: currentSize, customViews });
    }
  }

  async componentDidMount() {
    const { match } = this.props;
    this.__mounted = true;

    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));

    API.graphql(graphqlOperation(
      muGraphQL.events.LIST_SESSIONS,
      { clientID: getCachedClientId(), eventID: getCachedEventId(match.params.slug) }
    ))
      .then((result) => {
        this.setState({ sessions: result.data.listSessions });
        const { customTabs, customViews, dayTabs, eTag } = this.buildTabState();
        this.setState({ customTabs, customViews, dayTabs, eTag });
      })
      .catch(e => {
        console.log(`An error occured. (${JSON.stringify(e)})`);
        this.setState({ error: `An error occured. (${JSON.stringify(e)})` })
      });

    API.graphql(graphqlOperation(
      muGraphQL.events.GET_EVENT,
      { clientID: getCachedClientId(), eventID: getCachedEventId(match.params.slug) }
    ))
      .then((result) => {
        this.setState({ eventData: result.data.getEvent });
        const { customTabs, customViews, dayTabs, eTag } = this.buildTabState();
        this.setState( {customTabs, customViews, dayTabs, eTag});
      })
      .catch(e => {
        console.log(`An error occured. (${JSON.stringify(e)})`);
        this.setState({ error: `An error occured. (${JSON.stringify(e)})` })
      });
  }

  componentWillUnmount() {
    this.__mounted = false;
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  handleChange = (event, value) => {
    const { history, location, match, memoryCache } = this.props;
    const { lastScheduleDayCacheKey } = this.state;
    const urlParts = match.url.split('/');
    urlParts.splice(4, urlParts.length);
    urlParts.push(value);
    urlParts.push(location.search);

    memoryCache.setItem(lastScheduleDayCacheKey, value)

    history.push(urlParts.join('/'));
  };

  handleChangeIndex = index => {
    const { history, location, match, memoryCache } = this.props;
    const { lastScheduleDayCacheKey } = this.state;
    const urlParts = match.url.split('/');
    urlParts.splice(4, urlParts.length);
    urlParts.push(index);
    urlParts.push(location.search);

    memoryCache.setItem(lastScheduleDayCacheKey, index)

    history.push(urlParts.join('/'));
  };

  render() {
    const { classes, theme } = this.props;
    const { customTabs, customViews, eventData, value } = this.state;

    if (!eventData || !eventData.dates || eventData.dates.length !== 1) {
      return (
        <div className={ classes.root }>
          <Typography variant='h6'>This conference has no sessions.</Typography>
        </div>
      );
    }

    return (
      <div className={ classes.root }>
        <Tabs
          value={value}
          onChange={this.handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="off"
        >
          { customTabs }
        </Tabs>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={this.handleChangeIndex}
          className={ classes.viewsRoot }
        >
          { customViews }
        </SwipeableViews>
      </div>
    );
  }
}

export default withRouter(withMemoryCache(withStyles(styles, { withTheme: true })(Schedule)));
