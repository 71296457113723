import React from 'react';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import urljoin from 'url-join';

const DefaultRedirect = (props) => {
  const { location, match, page } = props;
  return (
    <Redirect to={`${urljoin(match.url, page)}${location.search}`} />
  );
}

export default withRouter(DefaultRedirect);