import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { setCachedEventData } from '../../hoc/withData';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
  },
  left: {
    flex: 2.10
  },
  right: {
    flex: .90,
    textAlign: 'right',
  },
  title: {
  },
  eventImage: {
    maxWidth: '100%',
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(3)
  },
  noUnderline: {
    textDecoration: 'none',
  }
});

const ListItem = (props) => {
  const { classes, data, location } = props;

  const description = data.description && data.description !== 'null' ? unescape(data.description) : '';
  const summary = data.summary && data.summary !== 'null' ? unescape(data.summary) : '';
  const hasImage = data.image && data.image.url;

  return (
    <div className={ classes.root }>
        <div className={ classes.left }>
          <Typography variant='h6' className={ classes.title }>
            {data.name}
          </Typography>
          <Typography variant='body2' dangerouslySetInnerHTML={ { __html: summary ? summary : description} } />
          <div>
            <Link className={ classes.noUnderline } to={`/conference/${data.slug}${location.search}`}>
              <Button
                className={ classes.button }
                color='primary'
                variant='contained'
                onClick={() => {
                  setCachedEventData(data)
                }}>Details</Button>
            </Link>
            { data.registrationUrl &&
            <a className={ classes.noUnderline } target="_blank" rel="noreferrer noopener" href={ data.registrationUrl }>
              <Button className={ classes.button } color='primary' variant='contained'>Register</Button>
            </a>
            }
          </div>
        </div>
        { hasImage &&
        <div className={ classes.right }>
          <img className={ classes.eventImage } src={ data.image.url } alt='event' />
        </div>
        }
    </div>
  );
}

export default withRouter(withStyles(styles, { withTheme: true })(ListItem));
