import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import ExhibitorItem from './ExhibitorItem';
import SearchField from '../../Components/SearchField';

import { TablePagination } from '@material-ui/core';
import { getCachedClientId, getCachedEventId } from '../../hoc/withData';
import { API, graphqlOperation } from 'aws-amplify';
import muGraphQL from '../../mu-graphql';

const styles = theme => ({
  root: {
    width: '100%',
    minHeight: 300,
    marginTop: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  pageRoot: {
    flex: '0 0 100%',
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      flex: '0 0 50%',
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    }
  },
  pageToolbar: {
    padding: 0,
    display: 'inline-flex',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    }
  },
  searchField: {
    flex: '0 0 100%',
    [theme.breakpoints.up('lg')]: {
      flex: '0 0 50%',
    }
  },
  searchInput: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.up('lg')]: {
      flexWrap: 'nowrap',
    }
  },
});
class Exhibitors extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      exhibitors: null,
      page: 0,
      rowsPerPage: 25,
      searchValue: ''
    }

    this.handleChangePage = this.handleChangePage.bind(this);

  }

  componentDidMount() {
    const { match } = this.props;
    this.__mounted = true;

    API.graphql(graphqlOperation(
      muGraphQL.events.LIST_EXHIBITORS,
      { clientID: getCachedClientId(), eventID: getCachedEventId(match.params.slug) }
    ))
      .then((result) => {
        this.setState({ exhibitors: result.data.listExhibitors });
      })
      .catch(e => {
        console.log(`An error occured. (${JSON.stringify(e)})`);
        this.setState({ error: `An error occured. (${JSON.stringify(e)})` })
      });
  }

  componentWillUnmount() {
    this.__mounted = false;
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };


  render() {
    const { classes } = this.props;
    const { page, rowsPerPage, searchValue } = this.state;
    const exhibitors = this.state.exhibitors ? (
      this.state.exhibitors.filter((exhibitor) => {
        if(searchValue === '') return true;

        const name = exhibitor.name;
        const description = exhibitor.shortPhrase;
        const booth = exhibitor.booth;
        const category = exhibitor.category || "No Category";

        const searchParts = [];

        if(name) searchParts.push(name.trim());
        if(description) searchParts.push(description.trim());
        if(booth) searchParts.push(booth.trim());
        if(category) searchParts.push(category.trim());

        const fullText = searchParts.join(' ');

        if(fullText && fullText.toLowerCase().includes(searchValue.toLowerCase())){
          return true;
        }
        return false;
      })
    ) : [];


    return (
      <div className={ classes.root }>
          <div className={ classes.searchInput }>
            <SearchField
              className={ classes.searchField }
              onChange={ (value) => this.setState({ searchValue: value })}
              onClose={ () => this.setState({ searchValue: '' })}
              placeholder={ 'Search exhibitors' }
              value={ searchValue }
            />

            <TablePagination
              classes={ {
                root: classes.pageRoot,
                toolbar: classes.pageToolbar
              }}
              component='div'
              count={ exhibitors.length }
              onPageChange={ this.handleChangePage }
              page={ page }
              rowsPerPage={ rowsPerPage }
              rowsPerPageOptions={[]}
            />

          </div>
          <div className={ classes.container }>
            { exhibitors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort()
              .map((exhibitor) => (
                <ExhibitorItem key={exhibitor.id} data={ exhibitor }/>
              )
            )}
          </div>
      </div>

    );
  }
}

export default withStyles(styles, { withTheme: true })(Exhibitors);
