import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import querystring from 'query-string';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { Auth, Logger, JS } from 'aws-amplify';
import { AuthPiece } from 'aws-amplify-react'
import { AuthenticationDetails } from 'amazon-cognito-identity-js';

import AuthPieceWrapper from './AuthPieceWrapper';

import styles from './_styles';

import { AUTHENTICATION_TYPE, AUTHENTICATION_TYPES } from './Enums';

const logger = new Logger('Authenticator');

const VIEWS = Object.freeze({
  "CONFIRM": "CONFIRM",
  "CODE": "CODE",
  "NOT_AUTHORIZED": "NOT_AUTHORIZED",
  "RESEND": "RESEND"
})

class SignInEmailOnlyChallengeCode extends AuthPiece {

  constructor(props) {
    super(props);

    this.checkContact = this.checkContact.bind(this);
    this.resend = this.resend.bind(this);
    this.sendCustomChallengeAnswer = this.sendCustomChallengeAnswer.bind(this);

    this.showConfirmView = this.showConfirmView.bind(this);
    this.showCodeView = this.showCodeView.bind(this);

    this._validAuthStates = ['signInEmailOnlyChallengeCode'];
    this.state = { code: '', view: VIEWS.CONFIRM };
  }

  componentDidMount() {
    this.__mounted = true;
  }

  componentWillUnmount() {
    this.__mounted = false;
  }

  checkContact(user) {
    Auth.verifiedContact(user)
      .then(data => {
        if (!JS.isEmpty(data.verified)) {
          this.changeState('signedIn', user);
        } else {
          user = Object.assign(user, data);
          this.changeState('verifyContact', user);
        }
      });
  }

  async resend() {
    const username = this.usernameFromAuthData();

    try {
      await Auth.signInWithoutPassword(new AuthenticationDetails({ Username: username }))
    }
    catch (err) {
      //ignore error
    }
  }

  async sendCustomChallengeAnswer() {
    const { code } = this.state;
    const { authData } = this.props;

    try {
      const user = await Auth.sendCustomChallengeAnswer(authData, code.trim());
      // console.log({user});
      logger.debug(user);
      if (user.challengeName === 'CUSTOM_CHALLENGE') {
        // console.log('Challenge Code is incorrect.');
        this.setState({ error: 'Your access code does not match. Please double check it.' })
      }
      else {
        this.changeState('signedIn', user);
      }
    }
    catch (err) {
      if (err.code === "NotAuthorizedException") {
        this.setState({ view: VIEWS.NOT_AUTHORIZED })
      }
      else {
        this.setState({ error: "Access Code can not be empty."})
      }
    }
  }

  showConfirmView() {
    const { authData, classes, location } = this.props;
    const values = querystring.parse(location.search.slice(1));

    return (
      <AuthPieceWrapper>
        <Typography className={ classes.row } variant='h5'>Email sent</Typography>
        <Typography className={ classes.row } variant='subtitle1'>We have sent an access code to:</Typography>
        <Typography variant='subtitle2'>{ authData && authData.username }</Typography>
        <Typography className={ classes.row } variant='subtitle1'>You should get it shortly. If you don't see it, check your spam folder.</Typography>
        <div className={ classnames(classes.row, classes.buttons) }>
          <Button className={ classes.button } variant='contained' color='primary' onClick={ () => this.setState({view: VIEWS.CODE }) }>I got the access code!</Button>
          <Button className={ classes.button } variant='text' color='default' onClick={ () => this.setState({view: VIEWS.RESEND }) }>I didn't get an access code!</Button>
          <Button className={ classes.button } variant='text' color='default' onClick={ () => this.props.history.push(`${decodeURIComponent(values.redirectUrl)}`) } >Cancel</Button>
        </div>
      </AuthPieceWrapper>
    );
  }

  showCodeView() {
    const { classes, location } = this.props;
    const { username, code, error } = this.state;
    const values = querystring.parse(location.search.slice(1));

    return (
      <form onSubmit={ (e) => { e.preventDefault(); this.sendCustomChallengeAnswer(username);} }>
        <AuthPieceWrapper>
          <Typography className={ classes.row } variant='h5'>Enter access code</Typography>
          <Typography className={ classes.row } variant='subtitle1'>Please enter your 6 digit access code.</Typography>
          <TextField
            id="single-factor-code-text-field"
            autoFocus
            error={ !!error }
            helperText={ error }
            inputProps={{ maxLength: 6, autoComplete: "off" }}
            className={ classes.row }
            value={ code }
            name="code"
            placeholder='Type your access code'
            onChange={ (e) => {
              let newVal = e.target.value.replace(/[^0-9]/g, "")
              if (newVal.length > 6 || newVal > 999999)
                return;
              this.setState({ code: newVal })
            }} />
          <div className={ classnames(classes.row, classes.buttons) }>
            <Button className={ classes.button } variant='contained' color='primary' onClick={ () => { this.sendCustomChallengeAnswer(username); } }>Continue</Button>
            <Button className={ classes.button } variant='text' color='default' onClick={ () => this.setState({view: VIEWS.CONFIRM, code: '', error: undefined }) }>Resend access code</Button>
            <Button className={ classes.button } variant='text' color='default' onClick={ () => this.props.history.push(`${decodeURIComponent(values.redirectUrl)}`) } >Cancel</Button>
          </div>
        </AuthPieceWrapper>
      </form>
    );
  }

  showResendView() {
    const { authData, classes, location } = this.props;
    const values = querystring.parse(location.search.slice(1));

    return (
      <AuthPieceWrapper>
        <Typography className={ classes.row } variant='h5'>Things to check</Typography>
        <Typography className={ classes.row } variant='subtitle1'>Please make sure that you entered the correct email:</Typography>
        <Typography variant='subtitle2'>{ authData && authData.username }</Typography>
        <div className={ classnames(classes.row, classes.buttons) }>
          <Button className={ classes.button } variant='contained' color='primary' onClick={ () => { this.setState({ view: VIEWS.CONFIRM }); this.changeState('signIn', null); } }>Change Email</Button>
        </div>
        <Typography className={ classes.row } variant='subtitle1'>Check your spam folder and make sure it's not there. You can also try resending your access code.</Typography>
        <div className={ classnames(classes.row, classes.buttons) }>
          <Button className={ classes.button } variant='contained' color='primary' onClick={ () => { this.resend(); this.setState({view: VIEWS.CONFIRM }); } }>RESEND</Button>
          <Button className={ classes.button } variant='text' color='default' onClick={ () => this.props.history.push(`${decodeURIComponent(values.redirectUrl)}`) } >Cancel</Button>
        </div>
      </AuthPieceWrapper>
    );
  }

  showNotAuthorizedView() {
    const { classes } = this.props;

    return (
      <AuthPieceWrapper>
        <Typography className={ classes.row } variant='h5'>Access code failure</Typography>
        <Typography className={ classes.row } variant='subtitle1'>Code attempts have exceeded.</Typography>
        <div className={ classnames(classes.row, classes.buttons) }>
          <Button className={ classes.button } variant='contained' color='primary' onClick={ () => this.changeState('signIn') } >Return to log in</Button>
        </div>
      </AuthPieceWrapper>
    );
  }

  showComponent() {
    const { authenticationType } = this.props;
    const { view } = this.state;

    if (!(authenticationType === AUTHENTICATION_TYPE.VERIFIED_EMAIL ||
        authenticationType === AUTHENTICATION_TYPE.UNVERIFIED_EMAIL)) {
      return null;
    }

    let activeview = undefined;

    switch (view) {
      case VIEWS.CODE:
        activeview = this.showCodeView();
        break;
      case VIEWS.RESEND:
        activeview = this.showResendView();
        break;
      case VIEWS.NOT_AUTHORIZED:
        activeview = this.showNotAuthorizedView();
        break;
      default:
        activeview = this.showConfirmView();
        break;
    }

    return (
      <Fragment>
        {activeview}
      </Fragment>
    )
  }
}

SignInEmailOnlyChallengeCode.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  authenticationType: PropTypes.oneOf(AUTHENTICATION_TYPES).isRequired
};

export default withRouter(styles(SignInEmailOnlyChallengeCode));
