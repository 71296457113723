import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import querystring from 'querystring';

import { AuthPiece } from 'aws-amplify-react'

class SignedIn extends AuthPiece {

  constructor(props) {
    super(props);

    this._validAuthStates = ['signedIn'];
  }

  showComponent() {
    // console.log("SignedIn:showComponent", { match, location, values })
    const { location } = this.props;
    const values = querystring.parse(location.search.slice(1))

    return (<Redirect to={`${decodeURIComponent(values.redirectUrl)}`} />);
  }
}

export default withRouter(SignedIn);