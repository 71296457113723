import React, { Fragment } from 'react';
import classnames from 'classnames';

import Typography from '@material-ui/core/Typography';

import { Logger } from 'aws-amplify';

import { withRegistry } from '../../hoc';
import styles from './_styles';

// eslint-disable-next-line
const logger = new Logger('Authenticator');

class AuthPieceWrapper extends React.Component {
  render() {
    const { children, classes, registry } = this.props;
    // const { username, password, rememberMe } = this.state;

    const logo = registry.appHeaderLogoUrl && registry.appHeaderLogoUrl.url ? registry.appHeaderLogoUrl.url : null;
    const name = registry.appName || '';

    const helpText = registry.appUserPoolHelpText && registry.appUserPoolHelpText !== null ? unescape(registry.appUserPoolHelpText) : undefined; 

    return (
      <Fragment>
        <div className={ classes.root }>
          <div className={ classes.rootMargin }>
            { logo ? 
                <img className={ classes.logo } src={logo} alt={name} /> 
              : <Typography variant='h1'>{name}</Typography>
            }
            { children }
            { helpText &&
              <Typography className={ classnames(classes.row, classes.helpText) } variant='body2'  dangerouslySetInnerHTML={{ __html: helpText }} />
            }
          </div>
        </div>
      </Fragment>      
    );
  }
}

export default withRegistry(styles(AuthPieceWrapper));