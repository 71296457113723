import React, { Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { FaqItem } from '.'
import { Divider } from '@material-ui/core';
import { getCachedClientId, getCachedEventId } from '../../hoc/withData';
import { API, graphqlOperation } from 'aws-amplify';
import muGraphQL from '../../mu-graphql';

const styles = theme => ({
  root: {
    minHeight: 300,
    paddingTop: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(3),
  }
});

class Faq extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      resources: null
    };
  }

  componentDidMount() {
    const { match } = this.props;
    this.__mounted = true;

    API.graphql(graphqlOperation(
      muGraphQL.events.LIST_RESOURCES,
      { clientID: getCachedClientId(), eventID: getCachedEventId(match.params.slug) }
    ))
      .then((result) => {
        this.setState({ resources: result.data.listResources });
      })
      .catch(e => {
        console.log(`An error occured. (${JSON.stringify(e)})`);
        this.setState({ error: `An error occured. (${JSON.stringify(e)})` })
      });
  }

  componentWillUnmount() {
    this.__mounted = false;
  }

  render() {
    const { classes } = this.props;
    const resources = this.state.resources || [];

    return (
      <div className={ classes.root }>
        <Typography className={ classes.title } variant='h6'>Frequently Asked Questions</Typography>
        {
          resources.length !== 0 ?
            resources.map((resource, index) => (
              <Fragment key={ resource.id }>
                <FaqItem data={ resource } />
                { index !== resources.length - 1 && <Divider /> }
              </Fragment>
            ))
            :
            <div>No frequently asked questions</div>
        }
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Faq);
