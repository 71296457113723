import React, { Fragment } from 'react';
import classnames from 'classnames';

import { Auth, Logger } from 'aws-amplify';
import QRCode from 'qrcode.react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import styles from './_styles';

const logger = new Logger('Authenticator');

class TOTPSetupComp extends React.Component {
    constructor(props) {
        super(props);

        this.setup = this.setup.bind(this);
        this.showSecretCode = this.showSecretCode.bind(this);
        this.verifyTotpToken= this.verifyTotpToken.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.triggerTOTPEvent = this.triggerTOTPEvent.bind(this);

        this.state = {
            code: null,
            setupMessage: null
        };
    }

    componentDidMount() {
        this.setup();
    }

    triggerTOTPEvent(event, data, user) {
        if (this.props.onTOTPEvent) {
            this.props.onTOTPEvent(event, data, user);
        }
    }
    
    handleInputChange(evt) {
        this.setState({setupMessage: null});
        this.inputs = {};
        const { name, value, type, checked } = evt.target;
        const check_type = ['radio', 'checkbox'].includes(type);
        this.inputs[name] = check_type? checked : value;
    }

    setup() {
        this.setState({setupMessage: null});
        const user = this.props.authData;
        if (!Auth || typeof Auth.setupTOTP !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }

        Auth.setupTOTP(user).then((data) => {
            logger.debug('secret key', data);
            const code = "otpauth://totp/AWSCognito:"+ user.username + "?secret=" + data + "&issuer=AWSCognito";
            this.setState({code});
        }).catch((err) => logger.debug('totp setup failed', err));
    }

    verifyTotpToken() {
        if (!this.inputs) {
            logger.debug('no input');
            return;
        }
        const user = this.props.authData;
        const { totpCode } = this.inputs;
        if (!Auth || typeof Auth.verifyTotpToken !== 'function' || typeof Auth.setPreferredMFA !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        Auth.verifyTotpToken(user, totpCode)
            .then(() => {
                // set it to preferred mfa
                Auth.setPreferredMFA(user, 'TOTP');
                this.setState({setupMessage: 'Setup TOTP successfully!'});
                logger.debug('set up totp success!');
                this.triggerTOTPEvent('Setup TOTP', 'SUCCESS', user);
            })
            .catch(err => {
                this.setState({setupMessage: 'Setup TOTP failed!'});
                logger.error(err);
            });
    }

    showSecretCode(code) {
        const { classes } = this.props;

        if (!code) return null;
        return (
            <Fragment>
                <div className={ classes.row }>
                    <QRCode value={code} />
                </div>

                <TextField 
                    className={ classes.row } 
                    name="totpCode" 
                    placeholder='Enter Security Code'
                    onChange={ (e) => this.setState({ totpCode: e.target.value })} />
            </Fragment>
        );
    }

    render() {
        const { classes } = this.props;
        const code = this.state.code;

        return (
          <Fragment>
              <Typography className={ classes.row } variant='subtitle1'>Scan then enter verification code.</Typography>
              { this.state.setupMessage &&
                <div className={ classes.row}>
                  this.state.setupMessage
                </div>
              }
              {this.showSecretCode(code)}
              <div className={ classnames(classes.row, classes.buttons) }>
                <Button className={ classes.button } variant='contained' color='primary' onClick={this.verifyTotpToken}>Verify Security Token</Button>
                <Button className={ classes.button } color='default' onClick={() => this.changeState('signIn')}>Back to log in</Button>
              </div>
          </Fragment>            
        );
    }
}

export default styles(TOTPSetupComp);