import React from 'react';
import { withRouter } from 'react-router-dom';

/** Closes the current window. Used by VideoSessionView to close the tab after the video ends. */
class CloseWindow extends React.Component {

  componentDidMount() {
    setTimeout(() => {
      window.close();
    }, 2000)
  }

  render() {
    return (
      <p>This window will close automatically, or you may close it.</p>
    );
  }
}

export default withRouter(CloseWindow);
