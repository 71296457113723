import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import SponsorItem from './SponsorItem';
import SearchField from '../../Components/SearchField';

import {  Divider } from '@material-ui/core';
import { getCachedClientId, getCachedEventId } from '../../hoc/withData';
import { API, graphqlOperation } from 'aws-amplify';
import muGraphQL from '../../mu-graphql';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  divider: {
    marginBottom: theme.spacing(2)
  },
  root: {
    width: '100%',
    minHeight: 300,
    paddingTop: theme.spacing(3),
  },
  searchInput: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.up('lg')]: {
      flexWrap: 'nowrap',
    }
  },
  searchField: {
    flex: '0 0 100%',
    [theme.breakpoints.up('lg')]: {
      flex: '0 0 50%',
    }
  },
  sponsorLevel: {
    marginTop: theme.spacing(3),
    '&:first-child': {
      marginTop: 0,
    }
  },
  separator: {
    height: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(),
  }
});
class Sponsors extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      searchValue: '',
      sponsors: null,
      sponsorLevels: null
    };
  }

  updateDimensions() {
    try {
      const { theme } = this.props;
      const { size } = this.state;

      const currentSize = window.innerWidth < theme.breakpoints.values.lg ? "small" : "normal";
      if (size !== currentSize && this.__mounted) {
        this.setState({ size: currentSize });
      }
    } catch {

    }
  }

  componentDidMount() {
    const { match } = this.props;
    this.__mounted = true;

    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));

    API.graphql(graphqlOperation(
      muGraphQL.events.LIST_SPONSORS,
      { clientID: getCachedClientId(), eventID: getCachedEventId(match.params.slug) }
    ))
      .then((result) => {
        this.setState({ sponsors: result.data.listSponsors });
      })
      .catch(e => {
        console.log(`An error occured. (${JSON.stringify(e)})`);
        this.setState({ error: `An error occured. (${JSON.stringify(e)})` })
      });

    API.graphql(graphqlOperation(
      muGraphQL.events.LIST_SPONSOR_LEVELS,
      { clientID: getCachedClientId(), eventID: getCachedEventId(match.params.slug) }
    ))
      .then((result) => {
        this.setState({ sponsorLevels: result.data.listSponsorLevels });
      })
      .catch(e => {
        console.log(`An error occured. (${JSON.stringify(e)})`);
        this.setState({ error: `An error occured. (${JSON.stringify(e)})` })
      });
  }

  componentWillUnmount() {
    this.__mounted = false;
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
    const { classes } = this.props;
    const { searchValue, /* size */ } = this.state;
    const sponsors = this.state.sponsors ? (
      this.state.sponsors.filter((sponsor) => {
        if(searchValue === '') return true;

        const name = sponsor.name;
        const description = sponsor.shortPhrase;

        const searchParts = [];

        if(name) searchParts.push(name.trim());
        if(description) searchParts.push(description.trim());

        const fullText = searchParts.join(' ');

        if(fullText && fullText.toLowerCase().includes(searchValue.toLowerCase())){
          return true;
        }
        return false;
      })
    ) : [];
    const levels = this.state.sponsorLevels ? this.state.sponsorLevels.sort((l1, l2) => l1.order - l2.order) : [];
    const sponsorGroups = [];
    let count = 0;
    let filteredSponsors = [];


    for(let i in levels){
      filteredSponsors = (sponsors.filter((sponsor) =>
        (sponsor.level && (sponsor.level.name === levels[i].name))));
      if(filteredSponsors.length !== 0) {
        sponsorGroups.push(
          <div key={ levels[i].id } className={ classes.sponsorLevel }>
            <Typography className={ classes.title } variant='h6'>{`${levels[i].name}`}</Typography>
            <Divider className={ classes.divider }/>
            <div className={ classes.container }>
                { filteredSponsors.sort((s1, s2) => s1.priority - s2.priority).map((sponsor) => (
                    <SponsorItem key={ sponsor.id } data={ sponsor }/>
                  ))
                }
            </div>
            <div className={classes.separator} />
          </div>
        );
        count++;
      }
    }

    filteredSponsors = sponsors.filter((sponsor) => (!sponsor.level));

    if(filteredSponsors.length !== 0){
      sponsorGroups.push(
        <div key={ count } className={ classes.sponsorLevel }>
          <Typography className={ classes.title } variant='h6'>No Sponsor Level</Typography>
          <Divider className={ classes.divider }/>
          <div className={ classes.container }>
            <div>
              { filteredSponsors
                .map((sponsor) => (
                  <div key={sponsor.id} style={{ height: 'auto'}}>
                    <SponsorItem key={ sponsor.id } data={ sponsor } />
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={ classes.root }>
        <div>
          <div className={ classes.searchInput }>
            <SearchField
              className={ classes.searchField }
              onChange={ (value) => this.setState({ searchValue: value })}
              onClose={ () => this.setState({ searchValue: '' })}
              placeholder={ 'Search sponsors' }
              value={ searchValue }
            />
          </div>
          <div>
            { sponsorGroups }
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Sponsors);
