import React from 'react';

import { withRouter } from 'react-router-dom';

import {
  Typography,
  Divider,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
  bio: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  divider: {
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
  },
  flexContainer: {
    display: 'flex'
  },
  container: {
    width: '100%',
  },
  image: {
    flex: '0 0 80px',
    marginRight: theme.spacing(),
  },
  img: {
    display: 'block',
    maxHeight: '100%',
    maxWidth: '100%',
    borderRadius: 5,
  },
  richText: {
    flex: 2,
    marginRight: theme.spacing(3),
    fontSize: '.9em',
    '& p:first-child': {
      margin: 0
    }
  },
});

class SpeakerItem extends React.PureComponent {

  constructor(props) {
    super(props);

    this.inRef = React.createRef();

    this.state = {
    }
  }

  fullname(defaultName) {
    const { data } = this.props;
    const prefix = data.person && data.person.prefix;
    const firstName = data.person && data.person.firstName;
    const middleName = data.person && data.person.middleName;
    const lastName = data.person && data.person.lastName;

    const nameParts = [];

    if (prefix) nameParts.push(prefix.trim());
    if (firstName) nameParts.push(firstName.trim());
    if (middleName) nameParts.push(middleName.trim());
    if (lastName) nameParts.push(lastName.trim());

    if (nameParts.length === 0) return defaultName;

    return nameParts.join(' ');

  }

  render() {
    const {
      classes,
      data
    } = this.props;

    const sessions = data.times ? [...new Set(data.times.map(time => time.session.name))] : [];

    return (
      <div className={classes.container}>
        <div className={classes.flexContainer}>
          {data.headshot && data.headshot.url &&
            <div className={classes.image}>
              <img className={classes.img} alt='' src={data.headshot.url} />
            </div>
          }
          <div className={classes.info}>
            <Typography variant='h6'>{this.fullname()}</Typography>
            {data.title &&
              <Typography variant='subtitle2' color='textSecondary'>{data.title}</Typography>
            }
            <Typography variant='subtitle2' color='textSecondary'>{data.affiliation}</Typography>
          </div>
        </div>
        <div>
          <div>
            <div className={classes.bio}>
              <Typography className={classes.richText} variant='body2' dangerouslySetInnerHTML={{ __html: data.bio }} />
            </div>
            <Typography variant='body2' color='textSecondary'>Sessions</Typography>
            {sessions.map((sessionName, index) => {
              return (
                <div key={index}>
                  <Typography variant='subtitle2'>{sessionName}</Typography>
                  {index < sessions.length - 1 && <Divider className={classes.divider} />}
                </div>
              )
            })
            }
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(SpeakerItem));
