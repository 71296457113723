import { withRouter } from 'react-router-dom';

import { ConsoleLogger as Logger, Hub } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';

import { AuthPiece } from 'aws-amplify-react'
// import Constants from 'aws-amplify-react/dist/Auth/common/constants';
import { withRememberMe } from '../../hoc';

const logger = new Logger('Authenticator');

class SignOut extends AuthPiece {
  constructor(props) {
    super(props);

    this.signOut = this.signOut.bind(this);
    Hub.listen('auth', (capsule) => this.onHubCapsule(capsule));
    this.state = {};
  }

  componentDidMount() {
    this._isMounted = true;
    this.findState();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  findState(){
    if (!this.props.authState && !this.props.authData) {
      Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({
          authState: 'signedIn',
          authData: user,
          stateFromStorage: true
        })
      })
      .catch(err => logger.error(err));
    } else if (this.props.stateFromStorage) {
      this.setState({
        stateFromStorage: true
      })
    }
  }

  onHubCapsule(capsule) {
    if (this._isMounted) {
      const { channel, payload } = capsule;
      if (channel === 'auth' && payload.event === 'signIn') {
        this.setState({
          authState: 'signedIn',
          authData: payload.data
        })
      } else if (channel === 'auth' && payload.event === 'signOut' && (!this.props.authState)) {
        this.setState({
          authState: 'signIn'
        })
      }

      if (channel === 'auth' && payload.event === 'signIn' && (!this.props.authState)) {
        this.setState({stateFromStorage: true})
      }
    }
  }

  signOut() {
    const { history, location, rememberMeSignOut } = this.props;
    let payload = {};
    // try {
    //     payload = JSON.parse(localStorage.getItem(Constants.AUTH_SOURCE_KEY)) || {};
    //     localStorage.removeItem(Constants.AUTH_SOURCE_KEY);
    // } catch (e) {
    //     logger.debug(`Failed to parse the info from ${Constants.AUTH_SOURCE_KEY} from localStorage with ${e}`);
    // }
    logger.debug('sign out from the source', payload);
    // const { googleSignOut, facebookSignOut, amazonSignOut, auth0SignOut } = this.props;
    // switch (payload.provider) {
    //   case Constants.GOOGLE:
    //     if (googleSignOut) googleSignOut();
    //     else logger.debug('No Google signout method provided');
    //     break;
    //   case Constants.FACEBOOK:
    //     if (facebookSignOut) facebookSignOut();
    //     else logger.debug('No Facebook signout method provided');
    //     break;
    //   case Constants.AMAZON:
    //     if (amazonSignOut) amazonSignOut();
    //     else logger.debug('No Amazon signout method provided');
    //     break;
    //   case Constants.AUTH0:
    //     if (auth0SignOut) auth0SignOut(payload.opts);
    //     else logger.debug('No Auth0 signout method provided');
    //     break;
    //   default:
    //     break;
    // }

    if (!Auth || typeof Auth.signOut !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }
    Auth.signOut()
      .then(() => {
        if (!this.state.stateFromStorage) {
          this.changeState('signedOut');
        }
        rememberMeSignOut();
        history.push(`/${location.search}`);
      })
      .catch(err => { logger.debug(err); this.error(err); });
  }

  render() {
    this.signOut();

    return null;
  }
}

export default withRememberMe(withRouter(SignOut));
