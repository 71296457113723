import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import muGraphQL from '../../mu-graphql';
import { API, graphqlOperation } from 'aws-amplify';
import { getCachedClientId, getCachedEventId } from '../../hoc/withData';

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(2),
    minHeight: 300
  },
  richText: {
    '& p:first-child': {
      margin: 0
    }
  }
});

class About extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      eventData: null
    };
  }

  componentDidMount() {
    const { match } = this.props;
    this.__mounted = true;

    API.graphql(graphqlOperation(
      muGraphQL.events.GET_EVENT,
      { clientID: getCachedClientId(), eventID: getCachedEventId(match.params.slug) }
    ))
      .then((result) => {
        this.setState({eventData: result.data.getEvent});
      })
      .catch(e => {
        console.log(`An error occured. (${JSON.stringify(e)})`);
        this.setState({ error: `An error occured. (${JSON.stringify(e)})` })});
  }

  componentWillUnmount() {
    this.__mounted = false;
  }


  render() {
    const { classes } = this.props;
    let description = unescape(this.state.eventData?.description);

    if (!description || description === 'null') {
      description = '<span>No Description.</span>';
    }

    return (
      <div className={ classes.root }>
        <Typography className={ classes.richText } variant='body2' dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(About);
