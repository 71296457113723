import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    margin: `${theme.spacing(3)}px 0`
  },
  richText: {
    '& p:first-child': {
      margin: 0
    }
  },
  title: {
    fontSize: '1rem'
  }
});

class FaqItem extends React.PureComponent {
  render() {
    const { classes, data } = this.props;
    // console.log('FaqItem:render', {props: this.props});

    const content = unescape(data.content);
    if (!data.title || !data.content || content === 'null') {
      return null;
    }

    return (
      <div className={ classes.root }>
        <Typography className={ classes.title } variant='subtitle2'>{data.title}</Typography>
        <Typography variant='subtitle1'>{data.shortPhrase}</Typography>
        <Typography className={ classes.richText } variant='body2' component={ 'div' } dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(FaqItem);
