import * as React from 'react';

import { Auth, Logger, JS } from 'aws-amplify';
import { AuthPiece } from 'aws-amplify-react'
import AuthPieceWrapper from './AuthPieceWrapper';

import TOTPSetupComp from './TOTPSetupComp';

const logger = new Logger('Authenticator');

class TOTPSetup extends AuthPiece {
    constructor(props) {
        super(props);

        this._validAuthStates = ['TOTPSetup'];
        this.onTOTPEvent = this.onTOTPEvent.bind(this);
        this.checkContact = this.checkContact.bind(this);
    }

    checkContact(user) {
        if (!Auth || typeof Auth.verifiedContact !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        Auth.verifiedContact(user)
            .then(data => {
                if (!JS.isEmpty(data.verified)) {
                    this.changeState('signedIn', user);
                } else {
                    const newUser = Object.assign(user, data);
                    this.changeState('verifyContact', newUser);
                }
            });
    }

    onTOTPEvent(event, data, user) {
        logger.debug('on totp event', event, data);
        // const user = this.props.authData;
        if (event === 'Setup TOTP') {
            if (data === 'SUCCESS') {
                this.checkContact(user);
            }
        }
    }

    showComponent(theme) {
        const { hide } = this.props;
        if (hide && hide.includes(TOTPSetup)) { return null; }

        return (
            <AuthPieceWrapper>
                <TOTPSetupComp {...this.props} onTOTPEvent={this.onTOTPEvent} />
            </AuthPieceWrapper>
        );
    }
}

export default TOTPSetup;