import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({

  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  container: {
    width: '100%',
    flex: '0 0 100%',
    marginBottom: theme.spacing(3),
    paddingRight: 0,
    paddingLeft: 0,
    [theme.breakpoints.up("lg")]: {
      flex: '0 0 50%',
      '&:nth-child(even)': {
        paddingLeft: theme.spacing()
      },
      '&:nth-child(odd)': {
        paddingRight: theme.spacing()
      },
    }
  },
  info: {
    flex: '1 1 100%',
  },
  grid: {
    width: '100%',
  },
  image: {
    flex: '0 0 50%',
    alignSelf: 'center',
    display: 'grid',
    justifyContent: 'flex-end',
  },
  img: {
    display: 'block',
    maxHeight: 80,
    maxWidth: '100%',
  },
  richText: {
    flex: 2,
    marginRight: theme.spacing(3),
    fontSize: '.9em',
    '& p:first-child': {
      margin: 0
    }
  },
  shortDescription: {
    marginBottom: theme.spacing(),
  },
});

class SponsorItem extends React.PureComponent {

  constructor(props) {
    super(props);

    this.inRef = React.createRef();

    this.state = {
      description: '',
    }
  }

  render() {
    const {
      classes,
      data,
    } = this.props;

    return (
        <div className={classes.container}>
          <div className={ classes.flexContainer }>
            <div className={ classes.info }>
              <Typography variant='h6'>{data.name}</Typography>
              <Typography className={ classes.shortDescription } gutterBottom variant='subtitle2' color='textSecondary'>{data.shortPhrase}</Typography>
            </div>
            { data.logo && data.logo.url &&
              <div className={ classes.image }>
                <img className={ classes.img } alt='' src={ data.logo.url } />
              </div>
            }
          </div>
          <Typography className={ classes.richText } variant='body2' dangerouslySetInnerHTML={{ __html: data.description }} />
        </div>
    );
  }

}

export default withRouter(withStyles(styles, { withTheme: true })(SponsorItem));
