/** urlPath must be of form "/conference/the-slug/schedule/0/5wAxtGp_U:p5iDi-IjK:hJtPdjKJu/video" */
export function getVideoMeetingFromUrl(urlPath: string, urlQueryString: string) {
  const urlParams = new URLSearchParams(urlQueryString),
    meetingId = urlParams.get('meetingId'),
    passcode = urlParams.get('passcode'),
    urlArray = urlPath.split('/'),
    indexOfId = 5,
    eventId = urlArray[indexOfId].split(':')[0];

  return {
    eventId,
    videoMeeting: {
      meetingId: meetingId,
      passcode: passcode,
    },
  };
}
