import * as React from 'react';
import classnames from 'classnames';

import { Auth, Logger } from 'aws-amplify';
import { AuthPiece } from 'aws-amplify-react'
import AuthPieceWrapper from './AuthPieceWrapper';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import styles from './_styles';

const logger = new Logger('Authenticator');

class ForgotPassword extends AuthPiece {
    constructor(props) {
        super(props);

        this.send = this.send.bind(this);
        this.submit = this.submit.bind(this);

        this._validAuthStates = ['forgotPassword'];
        this.state = { delivery: null };
    }

    send() {
        const { authData={} } = this.props;
        const username = this.state.username || authData.username;
        if (!Auth || typeof Auth.forgotPassword !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        Auth.forgotPassword(username)
            .then(data => {
                logger.debug(data)
                this.setState({ delivery: data.CodeDeliveryDetails });
            })
            .catch(err => this.error(err));
    }

    submit() {
        const { authData={} } = this.props;
        const { code, password } = this.state;
        const username = this.state.username || authData.username;
        
        if (!Auth || typeof Auth.forgotPasswordSubmit !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        Auth.forgotPasswordSubmit(username, code, password)
            .then(data => {
                logger.debug(data);
                this.changeState('signIn');
                this.setState({ delivery: null });
            })
            .catch(err => this.error(err));
    }

    showComponent() {
      const { classes, hide, authData={} } = this.props;
      if (hide && hide.includes(ForgotPassword)) { return null; }
      
      return (
        <AuthPieceWrapper>
          <Typography className={ classes.row } variant='h5'>Reset your password</Typography>
          { this.state.delivery || authData.username ? 
            <React.Fragment>
              <TextField 
                className={ classes.row } 
                name="code"
                inputProps={ { autoComplete: 'one-time-code' }}
                placeholder='Type your access code'
                onChange={ (e) => this.setState({ code: e.target.value })} />
              <TextField 
                className={ classes.row } 
                type={'password'}
                name="newpassword"
                inputProps={ { autoComplete: 'off' }}
                placeholder='Type your new password'
                onChange={ (e) => this.setState({ newpassword: e.target.value })} />
            </React.Fragment>
            :
            <TextField 
              className={ classes.row } 
              name="username"
              placeholder='Type your email'
              onChange={ (e) => this.setState({ username: e.target.value })} />  
          }
          <div className={ classnames(classes.row, classes.buttons) }>
            { this.state.delivery || authData.username ? 
                <Button className={ classes.button } variant='contained' color='primary' onClick={this.submit}>Submit</Button> 
              :
                <Button  className={ classes.button } variant='contained' color='primary' onClick={this.send}>Send Code</Button>
            }              
            <Button className={ classes.button } variant='text' color='default' onClick={() => { this.setState({ delivery: null }); this.changeState('signIn'); }}>Back to Log In</Button>
          </div>
        </AuthPieceWrapper>            
      );
    }

}

export default styles(ForgotPassword);
