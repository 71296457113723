import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import querystring from 'query-string';

import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { Greetings } from './Login';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 64,
  },
  left: {
    flex: `0 0 ${theme.spacing(12)}px`
  },
  center: {
    overflow: 'hidden',
  },
  right: {
    flex: `0 0 ${theme.spacing(12)}px`
  },
  backLink: {
    display: 'flex',
    textDecoration: 'none',
    '& > svg': {
      marginLeft: -theme.spacing()
    }
  },
  greetings: {
    textAlign: 'right',
  },
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
});

class NavBar extends React.Component {
  render() {
    const { backText, backUrl, classes, location, showGreetings, title } = this.props;
    const qs = querystring.parse(location.search);
    const inApp = "true" === qs.inapp;
    const navBar = qs.navbar === undefined ? true : "true" === qs.navbar;

    return (
      <Fragment>
        <div className={ classes.root }>
          <div className={ classes.left }>
            <Link className={ classes.backLink } to={ backUrl } >
              <NavigateBeforeIcon color='primary' />
              <Typography color='primary' variant='body1'>{ backText }</Typography>
            </Link>
          </div>
          <div className={ classes.center }>
            { (!inApp || (navBar && inApp)) ?
              <Typography variant='h6' className={ classes.title }>{ title }</Typography>
              : null
            }
          </div>
          <div className={ classes.right }>
            { ((showGreetings && inApp) || (inApp && navBar)) ?
              <Greetings className={ classes.greetings } />
              : null
            }
          </div>
        </div>
        <Divider />
      </Fragment>
    )
  }
}

NavBar.propTypes =  {
  classes: PropTypes.object.isRequired,
  backText: PropTypes.string.isRequired,
  backUrl: PropTypes.string.isRequired,
  title: PropTypes.string,
  showGreetings: PropTypes.bool,
}

export default withRouter(withStyles(styles, { withTheme: true })(NavBar));
