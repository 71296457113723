import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import querystring from 'query-string';

import NavBar from '../NavBar';
import { withMemoryCache } from '../../hoc';

import { withStyles } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { Greetings } from '../Login';

import {
  About,
  Schedule,
  Speakers,
  Exhibitors,
  Sponsors,
  Faq,
} from '.';
import { getCachedClientId, getCachedEventName } from '../../hoc/withData';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  greetings: {
    flex: `0 0 ${theme.spacing(8)}px`,
    textAlign: 'right',
  },
  tabRoot: {
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
  }
});


class ItemView extends React.Component {
  constructor(props) {
    super(props);
    const { match } = props;

    this.tabRouteIndex = {
      'about': 0,
      'schedule': 1,
      'speakers': 2,
      'exhibitors': 3,
      'sponsors': 4,
      'faq': 5,
    }
    this.tabs = {
      0: { route: 'about', label: 'About', component: About },
      1: { route: 'schedule', label: 'Schedule', component: Schedule },
      2: { route: 'speakers', label: 'Speakers', component: Speakers, dataAccessor: "speakers" },
      3: { route: 'exhibitors', label: 'Exhibitors', component: Exhibitors, dataAccessor: "exhibitors" },
      4: { route: 'sponsors', label: 'Sponsors', component: Sponsors, dataAccessor: "sponsors" },
      5: { route: 'faq', label: 'FAQ', component: Faq, dataAccessor: "resources" },
    }

    this.state = {
      value: this.tabRouteIndex[match.params.tab],
    };
  }

  componentDidUpdate() {
    const { match } = this.props;
    const { value } = this.state;
    const newValue = this.tabRouteIndex[match.params.tab];
    if (value !== newValue) {
      this.setState({ value: newValue });
    }
  }

  handleChange = (event, value) => {
    const { history, location, match, memoryCache } = this.props;
    const urlParts = match.url.split('/');
    urlParts[3] = this.tabs[value].route;
    urlParts.splice(4, urlParts.length);
    if (value === 1) {
      const lastScheduleDayCacheKey = `${getCachedClientId()}:scheduleDayKey`;
      const lastScheduleDay = memoryCache.getItem(lastScheduleDayCacheKey);
      urlParts[urlParts.length] = lastScheduleDay || 0;
    }
    urlParts.push(location.search)
    history.push(urlParts.join('/'));
  };

  handleChangeIndex = index => {
    const { history, location, match } = this.props;
    const urlParts = match.url.split('/');
    urlParts[3] = this.tabs[index].route;
    urlParts.splice(4, urlParts.length);
    urlParts.push(location.search)
    history.push(urlParts.join('/'));
  };

  render() {
    const { classes, history, location, match, theme } = this.props;

    let customTabs = [];
    let customViews = [];

    for(let i in this.tabs) {
      // TODO KLH does this hide sponsors if there are none? ...yes it did
      // if (this.tabs[i].dataAccessor && (!data[this.tabs[i].dataAccessor] || data[this.tabs[i].dataAccessor].length === 0)) {
      //   continue;
      // }
      customTabs.push(
        <Tab
          key={i}
          classes={{ root: classes.tabRoot }}
          disableRipple
          label={this.tabs[i].label}
        />
        );
      const CustomView = this.tabs[i].component;
      customViews.push(<CustomView key={i} match={match} history={history} />);
    }


    const qs = querystring.parse(location.search);
    const inApp = "true" === qs.inapp;
    const navBar = qs.navbar === undefined ? true : "true" === qs.navbar;

    return (
      <div className={classes.root}>
        { (!inApp || (navBar && inApp)) ?
          <NavBar backText="Back" backUrl={ `/${location.search}` } title={getCachedEventName()} />
          : null
        }
        <div className={ classes.row }>
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="off"

          >
            { customTabs }
          </Tabs>
          { inApp && !navBar &&
            <Greetings className={ classes.greetings } />
          }
        </div>
        <SwipeableViews
          // animateHeight
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={this.state.value}
          onChangeIndex={this.handleChangeIndex}
        >
          { customViews }
        </SwipeableViews>
      </div>
    );
  }
}

ItemView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default
  withMemoryCache(
    withRouter(withStyles(styles, { withTheme: true })(ItemView))
  );
