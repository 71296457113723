import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import SearchField from '../../Components/SearchField';

import { Grid } from '@material-ui/core';


const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: 'rgba(0,0,0,0)',
    color: 'gray',
    fontSize: 16,
  },
  body: {
    color: 'black',
    fontSize: 16,
    fontWeight: 'bold',
  },
}))(TableCell);

const styles = theme => ({
  circularProgress: {
    display: 'block !important',
    margin: 'auto'
  },
  root: {
    marginTop: theme.spacing(3),
    width: '100%',
  },
  searchInput: {
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 500,
  },
});


function createRows(numRows) {
  let data = [];
  for(let i = 0; i < numRows; i++) {
    data.push({ id: i, name: 'John Smith', location: 'Manhattan, KS',
      company: 'Kansas State University', email: 'jsmith@ksu.edu'});
  }
  return data;
}

const rowData = createRows(177);

class Directory extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      rowsPerPage: 10,
      searchValue: '',
    }
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value })
  }

  render() {
    const { classes, /* data */ } = this.props;
    const { page, rowsPerPage, searchValue } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rowData.length - page * rowsPerPage);

    //console.log(data);
    return (
        /*
        <ApolloProvider>
          <Query
            query={ gql(muGraphQL.events.LIST_ATTENDEES) }
            variables={{ clientId: data.clientID, eventId: data.id }}
          >
            {({ loading, error, data, refect, networkStatus }) => {
              if(networkStatus === 4 || loading) {
                return (
                  <CircularProgress className={classes.circularProgress} />
                );
              }

              if(error) return `Error! ${error.message}`;

              console.log(data);
            }}
          </Query>
        </ApolloProvider>
        */
       <div className={classes.root}>
        <Grid item xs container direction='column'>
          <Grid className={ classes.searchInput } item direction='row-reverse' justify='flex-start' container>
            <SearchField
              onChange={ (value) => this.setState({ searchValue: value })}
              onClose={ () => this.setState({ searchValue: '' })}
              placeholder={ 'Search directory' }
              value={ searchValue }
            />
          </Grid>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell align='left'>Name</CustomTableCell>
                <CustomTableCell align='left'>Location</CustomTableCell>
                <CustomTableCell align='left'>Company</CustomTableCell>
                <CustomTableCell align='left'>Email</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => (
                  <TableRow className={classes.row} key={row.id}>
                    <CustomTableCell component='th' scope='row'>
                      {row.name}
                    </CustomTableCell>
                    <CustomTableCell align='left'>{row.location}</CustomTableCell>
                    <CustomTableCell align='left'>{row.company}</CustomTableCell>
                    <CustomTableCell align='left'>{row.email}</CustomTableCell>
                  </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows}}>
                  <TableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  colSpan={4}
                  count={rowData.length}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Grid>
       </div>
    );
  }
}

export default withStyles(styles, { withTeme: true })(Directory);
