import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import urljoin from 'url-join';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  item: {
    display: 'table-row',
  },
  itemFlex: {
    display: 'flex',
    flexFlow: 'column'
  },
  time: {
    display: 'table-cell',
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(),
    fontWeight: 500,
    opacity: '0.65',
    minWidth: 80
  },
  title: {
    opacity: 1.0,
    display: 'table-cell',
    paddingBottom: theme.spacing()
  },
  link: {
    textDecoration: 'none',
    '&:focus, &:hover, &:visited, &:link, &:active': {
      color: theme.palette.primary.main,
    }
  }
})
const ScheduleItem = (props) => {
  const { classes, data, location, match, hideTime, size } = props;

  // console.log("ScheduleItem:render", {props, size});
  const linkTo = urljoin(match.url, data.id, location.search);
  const timeStr = data.duration ? `${data.startTime} - ${data.endTime}` : data.startTime;

  return (
    <div key={data.id} className={ size === "small" ? classes.itemFlex : classes.item }>
      <Typography className={ classes.time }>{ hideTime ? '' : timeStr }</Typography>
      <Typography className={ classes.title } color='primary'>
        <Link className={ classes.link } to={linkTo}>{data.name}</Link>
      </Typography>
    </div>

  );
}

export default withRouter(withStyles(styles, { withTheme: true })(ScheduleItem));
