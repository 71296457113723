import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import NavBar from '../NavBar';
import { withMemoryCache } from '../../hoc';
import muGraphQL from '../../mu-graphql';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { API, graphqlOperation } from 'aws-amplify';
import { getCachedClientId, getCachedEventName, getCachedEventStartDate, getCachedVideoIntegration } from '../../hoc/withData';

const styles = theme => ({
  title: {
    marginTop: theme.spacing(2),
  },
  details: {
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  detailColumn: {
    flexFlow: 'column',
  },
  richText: {
    flex: 2,
    marginRight: theme.spacing(3),
    fontSize: '.9em',
    '& p:first-child': {
      margin: 0,
    },
  },
  subdetails: {
    flex: 1,
  },
  locationName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  speakerLabel: {
    marginTop: theme.spacing(3),
  },
  materialsLabel: {
    marginTop: theme.spacing(3),
  },
  materialLink: {
    textDecoration: 'none',
    '&:focus, &:hover, &:visited, &:link, &:active': {
      color: theme.palette.primary.main,
    },
    cursor: 'pointer',
  },
  videoJoinButton: {
    padding: '.5em .25em',
    background: '#6cb96c',
    borderRadius: '.25em',
    color: 'white',
    cursor: 'pointer',
    '&:focus, &:hover, &:visited, &:link, &:active': {
      background: '#52a252',
    },
    marginTop: '1em',
    display: 'inline-block',
    marginBottom: '1em',
  },
});

class SessionView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      session: null
    };
  }

  updateDimensions() {
    try {
      const { size } = this.state;

      const currentSize = window.innerWidth < 768 ? 'small' : 'normal';
      if (size !== currentSize && this.__mounted) {
        // console.log({ size: currentSize })
        this.setState({ size: currentSize });
      }
    } catch { }
  }

  componentDidMount() {
    const { match } = this.props;
    this.__mounted = true;

    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));

    const sessionId = match.params.id;
    console.log({sessionId})

    API.graphql(graphqlOperation(
      muGraphQL.events.GET_SESSION,
      { clientID: getCachedClientId(), sessionID: sessionId }
    ))
      .then((result) => {
        this.setState({ session: result.data.getSession });
      })
      .catch(e => {
        console.log(`An error occured. (${JSON.stringify(e)})`);
        this.setState({ error: `An error occured. (${JSON.stringify(e)})` })
      });
  }

  componentWillUnmount() {
    this.__mounted = false;
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  findSessionTime() {
    const { match } = this.props;
    const { session } = this.state;

    if (session) {
      for (let i = 0; i < (session.times || []).length; i++) {
        let sessionTime = session.times[i];
        if (match.params.id === sessionTime.id) {
          return { sessionTime, session };
        }
      }
    }

    return { sessionTime: null, session }
  }

  fullname(speaker) {
    const prefix = speaker.person && speaker.person.prefix;
    const firstName = speaker.person && speaker.person.firstName;
    const middleName = speaker.person && speaker.person.middleName;
    const lastName = speaker.person && speaker.person.lastName;

    const nameParts = [];

    if (prefix) nameParts.push(prefix.trim());
    if (firstName) nameParts.push(firstName.trim());
    if (middleName) nameParts.push(middleName.trim());
    if (lastName) nameParts.push(lastName.trim());

    if (nameParts.length === 0) return '';

    return nameParts.join(' ');
  }

  videoPath(sessionTime) {
    return window.location.pathname
      + `/video?meetingId=${sessionTime.videoMeeting.meetingId}&passcode=${sessionTime.videoMeeting.passcode ? sessionTime.videoMeeting.passcode : ''}`;
  }

  render() {
    const { classes, location, match } = this.props;
    const { size } = this.state;

    const videoIntegration = getCachedVideoIntegration();
    const hasIntegrationConfigured = videoIntegration && videoIntegration.apiKey && videoIntegration.apiSecret;

    const { sessionTime, session } = this.findSessionTime();

    if (!session || !sessionTime) {
        return (
          <div className={classes.root}>
            <Typography variant='h6'>Loading session...</Typography>
          </div>
        );
    }

    let description = unescape(session.description);
    if (description === 'null') {
      description = '';
    }

    const urlParts = match.url.split('/');
    urlParts.splice(urlParts.length - 1, 1);
    urlParts.push(location.search);

    const startDate = moment(getCachedEventStartDate(), 'YYYY-MM-DD');
    startDate.add(sessionTime.day - 1, 'days');
    const time = moment(`1970-01-01T${sessionTime.time}`, 'YYYY-MM-DDTHH:mm');
    const endTime = session.duration
      ? time.clone().add(session.duration, 'minutes')
      : undefined;
    const startTimeStr = time.format('hh:mm A');
    const timeStr = endTime
      ? `${startTimeStr} - ${endTime.format('hh:mm A')}`
      : startTimeStr;

    return (
      <div>
        <NavBar
          backText="Schedule"
          backUrl={urlParts.join('/')}
          showGreetings={true}
          title={getCachedEventName()}
        />
        <Typography variant="h6" className={classes.title}>
          {session.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="subtitle2">{`${startDate
            .format('dddd')
            .toUpperCase()}, ${startDate
              .format('MMM D')
              .toUpperCase()} \u30fb ${timeStr}`}</Typography>
        <div
          className={classnames(
            classes.details,
            size === 'small' ? classes.detailColumn : null
          )}>
          {description && (
            <Typography
              className={classes.richText}
              variant="body2"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}

          {sessionTime.venue ||
            sessionTime.hall ||
            (sessionTime.speakers && sessionTime.speakers.length > 0) ? (
            <div className={classes.subdetails}>
              {((sessionTime.venue && sessionTime.venue.name) ||
                (sessionTime.hall && sessionTime.hall.name)) && (
                  <Fragment>
                    <Typography variant="body2" color="textSecondary">
                      Location
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.locationName}>
                      {sessionTime.venue ? sessionTime.venue.name : ''}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.locationName}>
                      {sessionTime.hall ? sessionTime.hall.name : ''}
                    </Typography>
                  </Fragment>
                )}
              {sessionTime.speakers && sessionTime.speakers.length > 0 && (
                <Fragment>
                  <Typography
                    className={classes.speakerLabel}
                    variant="body2"
                    color="textSecondary">
                    {sessionTime.speakers.length > 1 ? 'Speakers' : 'Speaker'}
                  </Typography>
                  {sessionTime.speakers.map(s => {
                    return (
                      <Fragment key={s.id}>
                        <Typography variant="subtitle2">
                          {this.fullname(s)}
                        </Typography>
                        {s.title && (
                          <Typography variant="subtitle2" color="textSecondary">
                            {s.title}
                          </Typography>
                        )}
                        {s.affiliation && (
                          <Typography variant="subtitle2" color="textSecondary">
                            {s.affiliation}
                          </Typography>
                        )}
                      </Fragment>
                    );
                  })}
                </Fragment>
              )}
            </div>
          ) : null}
        </div>
        {sessionTime.videoMeeting && hasIntegrationConfigured && (
          <Link
            className={classes.noUnderline}
            target={sessionTime.videoMeeting.userIsAuthorized ? "_blank" : undefined}
            to={sessionTime.videoMeeting.userIsAuthorized ?
              this.videoPath(sessionTime)
              : `/signin?redirectUrl=${encodeURIComponent(location.pathname + location.search)}`
            }>
            <Button
              className={classes.button}
              color="primary"
              variant="contained">
              Join Session
            </Button>
          </Link>
        )}
        {session.materials && (
          <Fragment>
            <Typography
              className={classes.materialsLabel}
              variant="body2"
              color="textSecondary">
              Materials
            </Typography>
            {session.materials.map(m => (
              <Typography
                key={m.url}
                variant="subtitle2"
                className={classes.materialsEmpty}
                color="primary">
                <a
                  className={classes.materialLink}
                  href={m.url}
                  target="_blank"
                  rel="noopener noreferrer">
                  {m.attributes.name}
                </a>
              </Typography>
            ))}
          </Fragment>
        )}
      </div>
    );
  }
}

export default withMemoryCache(withRouter(withStyles(styles, { withTheme: true })(SessionView)));
