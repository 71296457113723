import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import { ScheduleItem } from '.'
import { Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'table'
  }
})
const ScheduleDayList = (props) => {
  const { classes, data, size } = props;

  let previousStartTime = undefined;
  let previousEndTime = undefined;

  // console.log({size})
  return (
    <div className={ classes.root }>
      {
        data.length !== 0 ?
        data.map(s => {
          const hideTime = previousStartTime === s.startTime && previousEndTime === s.endTime
          const Component = (props) => <ScheduleItem data={s} hideTime={ hideTime } size={ size } />

          previousStartTime = `${s.startTime}`;
          previousEndTime = `${s.endTime}`;
          return (
            <Component key={s.id} />
          );
        })
        :
        <Typography>No sessions today.</Typography>
      }
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(ScheduleDayList);