import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import querystring from 'query-string';

import { withStyles } from '@material-ui/core';

import { DefaultRedirect, Footer, Header, Landing, PageNotFound, CloseWindow } from '.';

import {
  ItemView,
  Register,
  SessionView,
  VideoSessionView
} from './Conference';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // minWidth: 640,
    maxWidth: 1024,
    margin: 'auto',
  },
  rootMargin: {
    margin: `0 ${theme.spacing(2)}px`,
    paddingBottom: theme.spacing(),
  }
})

class Home extends React.Component {
  render() {
    const { classes, location } = this.props;

    const qs = querystring.parse(location.search);
    const inApp = "true" === qs.inapp;

    // console.log({qs, location, inApp});

    return (
      <Fragment>
        <div className={ classes.root }>
          <div className={ classes.rootMargin }>
            { !inApp &&
              <Header />
            }
            <Switch>
              <Route component={Landing} path="/" exact />
              <Route path="/conference/:slug" exact render={(props) => <DefaultRedirect page="about" {...props} /> } />

              <Route component={Register} path="/conference/:slug/register" exact />
              <Route component={ItemView} path="/conference/:slug/:tab(about|faq)" exact />
              <Route component={ItemView} path="/conference/:slug/:tab(speakers)" exact />
              <Route component={ItemView} path="/conference/:slug/:tab(exhibitors)" exact />
              <Route component={ItemView} path="/conference/:slug/:tab(sponsors)" exact />
              {/* <Route component={ItemView} path="/conference/:slug/:tab(directory)" exact /> */}
              {/* <Route path="/conference/:slug/:tab(schedule)" exact render={(props) => <DefaultRedirect page="0" {...props} /> } /> */}
              <Route component={ItemView} path="/conference/:slug/:tab(schedule)/:day(\d+)?" exact />
              {/* (/^((\d{1,2})|([0-2]\d{1,2})|([3][0-5][0-9])|([3][6][0-5]))$/g) */}
              <Route component={SessionView} path="/conference/:slug/:tab(schedule)/:day(\d+)/:id" exact />
              <Route component={VideoSessionView} path="/conference/:slug/:tab(schedule)/:day(\d+)/:id/video" exact />
              <Route component={CloseWindow} path="/conference/:slug/:tab(schedule)/:day(\d+)/:id/video/leave" exact />

              <Route component={PageNotFound} />
            </Switch>

          </div>
        </div>
        { !inApp &&
          <Footer />
        }
      </Fragment>
      )
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles, { withTheme: true })(Home));
