import React, { Fragment } from 'react';
import classnames from 'classnames';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// import Typography from '@material-ui/core/Typography';

import { Auth, I18n, Logger, JS } from 'aws-amplify';
import { AuthPiece } from 'aws-amplify-react'
import AuthPieceWrapper from './AuthPieceWrapper';

import { withRegistry } from '../../hoc';

import styles from './_styles';

const logger = new Logger('Authenticator');


function convertToPlaceholder(str) {
  return str.split('_').map(part => part.charAt(0).toUpperCase() + part.substr(1).toLowerCase()).join(' ')
}

function objectWithProperties(obj, keys) {
  const target = {};
  for (const key in obj) {
      if (keys.indexOf(key) === -1) {
          continue;
      }
      if (!Object.prototype.hasOwnProperty.call(obj, key)) {
          continue;
      }
      target[key] = obj[key];
  }
  return target;
}

class RequireNewPassword extends AuthPiece {
  constructor(props) {
    super(props);
    
    this._validAuthStates = ['requireNewPassword'];

    this.change = this.change.bind(this);
    this.checkContact = this.checkContact.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {};
  }

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
  };
  
  checkContact(user) {
    if (!Auth || typeof Auth.verifiedContact !== 'function') {
        throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }
    Auth.verifiedContact(user)
        .then(data => {
            if (!JS.isEmpty(data.verified)) {
                this.changeState('signedIn', user);
            } else {
                user = Object.assign(user, data);
                this.changeState('verifyContact', user);
            }
        });
  }

  change() {
    const { authData } = this.props;
    const { password } = this.state;
    const { requiredAttributes } = authData.challengeParam;
    const attrs = objectWithProperties(this.state, requiredAttributes);

    if (!Auth || typeof Auth.completeNewPassword !== 'function') {
        throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }
    Auth.completeNewPassword(authData, password, attrs)
        .then(user => {
            logger.debug('complete new password', user);
            if (user.challengeName === 'SMS_MFA') {
                this.changeState('confirmSignIn', user);
            } else if (user.challengeName === 'MFA_SETUP') {
                logger.debug('TOTP setup', user.challengeParam);
                this.changeState('TOTPSetup', user);
            } else {
                this.checkContact(user);
            }
            
        })
        .catch(err => this.error(err));
  }

  showComponent(theme) {
    const { authData, classes } = this.props;
    // const { username, password, rememberMe } = this.state;    
    const { requiredAttributes } = authData.challengeParam;

    return (
      
      <AuthPieceWrapper>
        <Fragment>
          <TextField 
            className={ classes.row } 
            label='Change Password'
            // value={ password } 
            name="password" type="password" 
            placeholder='New Password'
            onChange={ this.handleChange } />

          {requiredAttributes & requiredAttributes
            .map(attribute => (
                <TextField
                    label={I18n.get(convertToPlaceholder(attribute))}
                    placeholder={I18n.get(convertToPlaceholder(attribute))}
                    name={attribute}
                    type="text"
                    className={classes.row}
                    onChange={ this.handleChange }
                    margin="normal"
                />
          ))}

          <div className={ classnames(classes.row, classes.buttons) }>
            {/* <Link className={ classes.backButtonLink } to="/"> */}
            <Button className={ classes.button } color='default' onClick={ () => this.changeState("signIn") } >Back to sign in</Button>
            {/* </Link>  */}
            <Button className={ classes.button } variant='contained' color='primary' onClick={ this.change }>Change</Button>
          </div>
        </Fragment>
      </AuthPieceWrapper>           
    )
  }
}

export default withRegistry(styles(RequireNewPassword));
