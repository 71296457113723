import { withStyles } from '@material-ui/core/styles';

const formMaxWidth = 360;

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: 960,
    margin: 'auto',
  },
  rootMargin: {
    margin: `0 ${theme.spacing(4)}px`,
    paddingBottom: theme.spacing(),
    paddingTop: theme.spacing(4),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing(2)}px 0`,
  },
  logoLink: {
    display: 'flex',
    textDecoration: 'none',
  },
  logo: {
    maxWidth: 800,
    maxHeight: 120,
    margin: 'auto',
  },
  row: {
    maxWidth: formMaxWidth,
    width: '100%',
    margin: 'auto',
    marginTop: theme.spacing(4),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
  },
  button: {
    marginTop: theme.spacing()
  },
  helpText: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  paddingLeftZero: {
    paddingLeft: 0
  },

});

export default withStyles(styles, { withTheme: true })
