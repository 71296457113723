import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import querystring from 'query-string';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { Auth, Logger, JS } from 'aws-amplify';
import { AuthPiece } from 'aws-amplify-react'
import { AuthenticationDetails } from 'amazon-cognito-identity-js';

import AuthPieceWrapper from './AuthPieceWrapper';
import { withRememberMe } from '../../hoc';

import { AUTHENTICATION_TYPE, AUTHENTICATION_TYPES } from './Enums';

import styles from './_styles';


const logger = new Logger('Authenticator');

function getRandomString(bytes) {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues).map(intToHex).join('');
}

function intToHex(nr) {
  return nr.toString(16).padStart(2, '0');
}

class SignInEmailOnly extends AuthPiece {

  constructor(props) {
    super(props);

    this.checkContact = this.checkContact.bind(this);
    this.signIn = this.signIn.bind(this);

    this._validAuthStates = ['signIn', 'signedOut', 'signedUp'];
    this.state = { username: '', password: '', rememberMe: false, checkedForCurrentUser: false};
  }

  componentDidMount() {
    this.__mounted = true;
  }  

  componentWillUnmount() {
    this.__mounted = false;
  }

  checkContact(user) {
    Auth.verifiedContact(user)
      .then(data => {
        if (!JS.isEmpty(data.verified)) {
          this.changeState('signedIn', user);
        } else {
          user = Object.assign(user, data);
          this.changeState('verifyContact', user);
        }
      });
  }

  async signIn(username, retry) {
    retry = !!retry;

    try {
      const user = await Auth.signInWithoutPassword(new AuthenticationDetails({ Username: username.trim() }))
      // console.log({user});
      logger.debug(user);
      if (user.challengeName === 'CUSTOM_CHALLENGE') {
        logger.debug('custom auth with ' + user.challengeName);
        this.changeState('signInEmailOnlyChallengeCode', user);
      } else if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        logger.debug('confirm user with ' + user.challengeName);
        this.changeState('confirmSignIn', user);
      } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        logger.debug('require new password', user.challengeParam);
        this.changeState('requireNewPassword', user);
      } else if (user.challengeName === 'MFA_SETUP') {
        logger.debug('TOTP setup', user.challengeParam);
        this.changeState('TOTPSetup', user);
      }
      else {
        this.checkContact(user);
      }
      this.setState({ username : '' });
  }
    catch (err) {
      // console.log({err});
      if (err && err.code === "UserNotFoundException" && !retry) {
        try {
          await Auth.signUp({
            username: username.trim(),
            password: getRandomString(30)
          });
          this.signIn(username, true);
        }
        catch (newUserErr) {
          this.setState({ error: "Error logging in, please try again."})
        }
      }
      this.setState({ error: "Email address is required." });
    }
  }  

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  showComponent() {
    const { classes, authenticationType, location, onRememberMe, rememberMeValue } = this.props;
    const { username, error } = this.state; 

    if (!(authenticationType === AUTHENTICATION_TYPE.VERIFIED_EMAIL || 
        authenticationType === AUTHENTICATION_TYPE.UNVERIFIED_EMAIL)) {
      return null;
    }

    const values = querystring.parse(location.search.slice(1));
    return (
      <form onSubmit={ (e) => { e.preventDefault(); this.signIn(username); } }>
        <AuthPieceWrapper>
          <Typography className={ classes.row } variant='subtitle1'>Please enter the email you registered with.</Typography>
          <TextField
            id="username-text-field"
            error={ !!error }
            helperText={ error }
            autoFocus
            type="email"
            inputProps={{autoComplete: "username"}}
            className={ classes.row } 
            name="username" 
            placeholder='Type your email'
            onChange={ (e) => this.setState({ username: e.target.value })} />        
          <FormControlLabel
            className={ classnames( classes.row, classes.button) }
            control={
              <Checkbox
                checked={ rememberMeValue }
                className={ classes.paddingLeftZero }
                onChange={ onRememberMe }
                value="rememberMe"
                color="primary"
              />
            }
            label="Remember Me"
          />        
          <div className={ classnames(classes.row, classes.buttons) }>
            <Button className={ classes.button } variant='contained' color='primary' onClick={ () => { this.signIn(username); } }>Log in</Button>
            <Button className={ classes.button } variant='text' color='default' onClick={ () => this.props.history.push(`${decodeURIComponent(values.redirectUrl)}`) } >Cancel</Button>
          </div>
        </AuthPieceWrapper>
      </form>
    );
  }
}

SignInEmailOnly.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  authenticationType: PropTypes.oneOf(AUTHENTICATION_TYPES).isRequired
};

export default withRememberMe(withRouter(styles(SignInEmailOnly)));