export const AUTHENTICATION_TYPE = Object.freeze({
  VERIFIED_EMAIL_AND_PASSWORD: 'VERIFIED_EMAIL_AND_PASSWORD',
  VERIFIED_EMAIL: 'VERIFIED_EMAIL',
  UNVERIFIED_EMAIL: 'UNVERIFIED_EMAIL'
})

export const AUTHENTICATION_TYPES = Object.freeze([
  'VERIFIED_EMAIL_AND_PASSWORD',
  'VERIFIED_EMAIL',
  'UNVERIFIED_EMAIL',
]);