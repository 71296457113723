import React from 'react';

export default function withChangeFavicon(WrappedComponent) {

  return class extends React.Component {
    constructor(props) {
      super(props);

      this.changeFavicon = this.changeFavicon.bind(this);
    }

    changeFavicon(src) {
      let link = document.createElement('link'),
      oldLink = document.getElementById('favicon');
      
      // console.log("favicon changed", {src});
      link.id = 'favicon';
      link.rel = 'icon';
      link.href = src;
  
      if (oldLink) {
        document.head.removeChild(oldLink);
      }
      document.head.appendChild(link);
    }  

    render() {
      return <WrappedComponent changeFavicon={this.changeFavicon} {...this.props} />;
    }
  }
}