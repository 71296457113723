import React, { Fragment } from 'react';
import classnames from 'classnames';

import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { withData, withRegistry } from '../hoc';
import muGraphQL from '../mu-graphql';

import {
  List,
} from './Conference';

const styles = theme => ({
  description: {
    margin: `${theme.spacing(3)}px 0`
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column',
  },
  storeContainer: {
    margin: `${theme.spacing(3)}px 0`,
  },
  appStoreIcon: {
    height: 72,
    padding: 'calc(.16 * 72px) 0'
  },
  appStoreIconCenter: {
    padding: 'calc(.32 * 72px) 0',
    paddingBottom: 0,
  },
  playStoreIcon: {
    height: 72,
  },
  noAppStore: {
    marginLeft: -11,
  },
  linearContainer: {
    position: 'relative',
  },
});

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = { small: window.innerWidth < 400};
  }

  updateDimensions() {
    const { small } = this.state;

    const  isSmall = window.innerWidth < 400;
    if (small !== isSmall && this.__mounted) {
      this.setState({ small: isSmall });
    }
  }

  async componentDidMount() {
    this.__mounted = true;

    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    this.__mounted = false;
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }
  render() {
    const { classes, data, registry } = this.props;
    const { small } = this.state;

    const description = registry.appDescription && registry.appDescription !== 'null' ? unescape(registry.appDescription) : '';

    // console.log({refreshingCache})
    return (
      <Fragment>
        <Typography className={ classes.description } variant='body2' dangerouslySetInnerHTML={{ __html: description }} />
        <List data={ data } />
        { (registry.appAppStoreUrl || registry.appPlayStoreUrl) &&
        <div className={ classes.storeContainer }>
          <Typography variant='subtitle2'>Download the app for your phone</Typography>
          <Typography variant='body2'>For the best experience, download our app:</Typography>
          <div className={ small ? classes.center : null }>
            { registry.appAppStoreUrl &&
              <a href={ registry.appAppStoreUrl } rel="noopener noreferrer" target='_blank'>
                <img className={ small ? classnames(classes.appStoreIcon, classes.appStoreIconCenter) : classes.appStoreIcon } src='/images/app-store-black.svg' alt='' />
              </a>
            }
            { registry.appPlayStoreUrl &&
              <a href={ registry.appPlayStoreUrl } rel="noopener noreferrer"  target='_blank'>
                <img className={ classnames(classes.playStoreIcon, (!registry.appAppStoreUrl ? classes.noAppStore : null)) } src='/images/google-play.png' alt='' />
              </a>
            }
          </div>
        </div>
        }

      </Fragment>
    );
  }
}

export default
  withRegistry(
  withData({
    query: muGraphQL.events.LIST_EVENTS,
    cacheQuery: muGraphQL.events.LIST_EVENTS,
    queryDataAccessor: 'listEvents',
  }, withStyles(styles, { withTheme: true })(Landing)));
